/*------------------------------------*\
	# BUTTONS
\*------------------------------------*/

/* = FORM BUTTONS
----------------------------------------------- */

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	// background: color__(primary);
	// border: none;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
}

// button:hover,
// input[type="button"]:hover,
// input[type="reset"]:hover,
// input[type="submit"]:hover {

// }

// button:focus,
// input[type="button"]:focus,
// input[type="reset"]:focus,
// input[type="submit"]:focus,
// button:active,
// input[type="button"]:active,
// input[type="reset"]:active,
// input[type="submit"]:active {

// }

/* = BUTTON RESET
----------------------------------------------- */

.button-reset,
.button-reset-child > button {
	background: none;
	border: none;
	padding: 0;
	text-align: left;
}

/* = GENERAL BUTTONS
----------------------------------------------- */

[class*="c-button-"] {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid currentColor;
	padding: 0.75em 1.375em; // Adjusted to match design

	@include font-size(14);
	font-family: $font__sans-serif;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.12em;
	text-decoration: none;
	text-align: center;

	transition: 0.2s ease-in-out all;

	cursor: pointer;

	@include bp($desktop) {
		@include font-size(16);
	}

	.site-content & {
		&::after {
			display: none; // Reset for common links
		}
	}
}

[class*="c-button-"] {
	&:hover {
		color: color__(white);
		background-color: color__(primary);
		border-color: color__(primary);
	}
}

[class*="c-button-"] {
	&:focus,
	&:active,
	&:focus {
		color: color__(white);
		background-color: color__(secondary);
		border-color: color__(secondary);
	}
}

.c-button--mirror {
	direction: rtl;
}

/* = BUTTON with ICON
----------------------------------------------- */

.c-button__text + .c-button__icon {
	margin-inline-start: 1em;
}

[class*="c-button-"] .c-button__icon {
	margin-inline-end: -0.5em; // Nicer button padding
}

/* = BUTTON 1
----------------------------------------------- */

.c-button-1 {
	color: color__(primary);
	background-color: transparent;
	border-color: currentColor;
}

/* = BUTTON - SMALL
----------------------------------------------- */

.c-button--sm {
	@include font-size(12);
}

/* = BUTTON LINK
----------------------------------------------- */

.c-link-1 {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	text-transform: uppercase;
	color: currentColor;
	text-decoration: none;
	border: none;

	@include font-size(14);
	font-family: $font__sans-serif;
	font-weight: 700;
	letter-spacing: 0.12em;

	@include bp($tablet) {
		@include font-size(16);
	}

	.c-button__text {
		display: block;
		border-bottom: 2px solid currentColor;
		transition: 0.2s ease-in-out all;
	}

	.c-button__icon {
		max-height: 0.8em;
	}

	.c-icon-external-link {
		position: relative;
		top: -0.15em;
	}

	&:hover,
	&:focus,
	&:active {
		color: color__(primary);

		.c-button__text {
			border-color: currentColor;
		}
	}
}

[class*="c-link-"] .c-button__text + .c-button__icon {
	margin-left: 0.5em;
}

.button-arrow {
	color: $primary-1-4;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	letter-spacing: 0.05em;
	text-decoration: none;
	display: flex;
	align-items: center;
	font-size: 14px;
	transition: 0.2s ease-in-out color;
	@include bp($md) {
		font-size: 16px;
	}
	.icon,
	.icon svg {
		width: 26px;
		height: 20px;
	}
	svg path {
		fill: $primary-1-2;
		transition: 0.2s ease-in-out fill;
	}
	&:hover,
	&:focus {
		color: $primary-1-5;
		svg path {
			fill: $primary-1-5;
		}
	}
}

.button-play {
	background-color: rgba($primary-1-9, 0.2);
	border: 1px solid $primary-1-1;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	text-decoration: none;
	background-repeat: no-repeat;
	display: block;
	background-position: center;
	transition: 0.2s ease-in-out background-color;
	&.play {
		background-image: url(../assets/img/icons/play.svg);
	}
	&.pause {
		background-image: url(../assets/img/icons/pause.svg);
	}
	&:hover {
		background-color: $primary-1-7;
	}
}

.button-rw {
	background-color: rgba($primary-1-9, 0.2);
	border: 1px solid $primary-1-1;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	text-decoration: none;
	background-image: url(../assets/img/icons/rw.svg);
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	transition: 0.2s ease-in-out background-color;
	&:hover,
	&:focus {
		background-color: $primary-1-7;
	}
}

.button-ff {
	background-color: rgba($primary-1-9, 0.2);
	border: 1px solid $primary-1-1;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	text-decoration: none;
	background-image: url(../assets/img/icons/ff.svg);
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	transition: 0.2s ease-in-out background-color;
	&:hover,
	&:focus {
		background-color: $primary-1-7;
	}
}

.button-arrow-circle {
	width: 100%;
	color: $primary-1-5;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	letter-spacing: 0.05em;
	text-decoration: none;
	display: flex;
	align-items: center;
	height: 60px;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid $neutral-3-4;
	font-size: 14px;
	background-image: url(../assets/img/icons/plus.svg);
	background-repeat: no-repeat;
	background-position: right;
	transition: 0.2s ease-in-out background-image;
	@include bp($md) {
		font-size: 20px;
	}
	&:hover,
	&:focus {
		background-image: url(../assets/img/icons/arrow-circle.svg);
		color: $primary-1-5;
	}
}

.button-next {
	outline: none;
	border: none;
	background-color: initial;
	padding: 0;
	width: fit-content;
	svg {
		width: 50px;
		height: 50px;
		path:nth-child(1) {
			fill: $primary-1-5;
			transition: 0.2s ease-in-out fill;
		}
		@include bp($md) {
			width: 60px;
			height: 60px;
		}
		@include bp($xl) {
			width: 70px;
			height: 70px;
		}
	}
	&:hover {
		svg path:nth-child(1) {
			fill: $primary-1-4;
		}
	}
}

.link-underline-primary {
	font-size: 14px;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	color: $primary-1-4;
	letter-spacing: 0.05em;
	text-decoration: none;
	padding-bottom: 0.2rem;
	border-bottom: solid 2px $primary-1-3;
	transition: 0.2s ease-in-out color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $primary-1-5;
	}
}

.link-underline-secondary {
	font-size: 14px;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	text-decoration: none;
	color: $secondary-1-1;
	letter-spacing: 0.05em;
	padding-bottom: 0.2rem;
	border-bottom: solid 2px $secondary-1-3;
	transition: 0.2s ease-in-out color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $secondary-1-2;
	}
}

.link-underline-secondary-two {
	font-size: 14px;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	text-decoration: none;
	color: $neutral-2-5;
	letter-spacing: 0.05em;
	padding-bottom: 0.2rem;
	border-bottom: solid 2px $neutral-2-6;
	transition: 0.2s ease-in-out color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $neutral-2-5;
	}
}

.link-underline-secondary-three {
	font-size: 14px;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	text-decoration: none;
	color: $primary-1-4;
	letter-spacing: 0.05em;
	padding-bottom: 0.2rem;
	border-bottom: solid 2px $neutral-2-6;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out border-bottom;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $primary-1-4;
		border-bottom: solid 2px $neutral-2-6;
	}
}

.link-round-primary {
	font-size: 14px;
	color: $primary-1-4;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 25px;
	border: 1px solid $primary-2-5;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $primary-1-7;
	}
}

.link-round-primary-two {
	font-size: 14px;
	color: $white;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 25px;
	border: 1px solid $primary-2-5;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $primary-1-7;
	}
}

.link-round-primary-three {
	border: 1px solid $primary-2-5;
	font-family: $font__sans-serif-headings;
	color: $white;
	font-size: 14px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	background-color: $primary-1-5;
	border-radius: 100px;
	padding: 15px 30px;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		background-color: $primary-1-7;
		color: $white;
	}
}

.link-round-secondary-two {
	font-size: 14px;
	color: $secondary-2-6;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 25px;
	border: 1px solid $secondary-2-3;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $secondary-2-6;
	}
}

.link-round-secondary-three {
	font-size: 14px;
	color: $secondary-1-8;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 25px;
	border: 1px solid $secondary-1-7;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $secondary-1-9;
	}
}

.link-round-tertiary {
	font-size: 14px;
	color: $white;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 25px;
	border: 1px solid $white;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color, 0.2s ease-in-out	border;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $tertiary-1-4;
		border: 1px solid $primary-1-5;
	}
}

.link-outline-primary {
	border: 1px solid $primary-1-1;
	font-family: $font__sans-serif-headings;
	color: $white;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.05em;
	padding: 15px 30px;
	text-decoration: none;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	&:hover,
	&:focus {
		background-color: $primary-1-7;
		color: $white;
	}
}

.link-outline-background-primary {
	border: 1px solid $primary-1-1;
	font-family: $font__sans-serif-headings;
	color: $white;
	background-color: rgba($primary-1-9, 0.2);
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.05em;
	padding: 15px 30px;
	text-decoration: none;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	&:hover,
	&:focus {
		background-color: $primary-1-7;
		color: $white;
	}
}

.accordion-button.accordion-button-custom {
	box-shadow: none;
	color: $primary-1-5;
	font-family: $font__sans-serif-headings;
	font-weight: 700;
	letter-spacing: 0.03em;
	text-decoration: none;
	font-size: 16px;
	border-bottom: 1px solid $primary-1-5;
	transition: all 0.2s ease-in-out;
	@include bp($md) {
		font-size: 20px;
	}
	&[aria-expanded="false"] {
		border-bottom: 1px solid $primary-1-5;
	}
	&:hover {
		color: $primary-1-5;
		border-bottom: 1px dotted $primary-1-5;
	}
}

.link-underline-dashed {
	border-bottom: 1px dashed;
	text-decoration: none;
	color: inherit;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out border-bottom;
	&:hover,
	&:focus {
		border-bottom: 1px solid;
		color: inherit;
	}
}

.link-underline {
	border-bottom: 1px transparent;
	text-decoration: none;
	color: inherit;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out border-bottom;
	&:hover,
	&:focus {
		border-bottom: 1px solid;
		color: inherit;
	}
}

.subscription-form input[type="submit"],
.submitOne {
	font-size: 14px;
	color: $white;
	background-color: $secondary-2-5;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 25px;
	margin: 0 auto 2.125rem 0;
	border: 1px solid $secondary-2-4;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color, 0.2s ease-in-out	border;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $secondary-2-2;
		border: 1px solid transparent;
	}
}

.enquiry-form input[type="submit"],
.submitTwo {
	font-size: 14px;
	color: $primary-1-4;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 50px;
	margin: 1rem auto 0 8px;
	background-color: initial;
	border: 1px solid $primary-2-5;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $white;
		background-color: $primary-1-7;
	}
}

.product-form input[type="submit"],
.submitThree {
	font-size: 14px;
	color: $neutral-3-2;
	font-family: $font__sans-serif-headings;
	padding: 15px 30px;
	text-decoration: none;
	font-weight: 700;
	letter-spacing: 0.05em;
	border-radius: 50px;
	margin: 1rem auto 0 8px;
	background-color: $secondary-2-7;
	border: none;
	transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;
	@include bp($md) {
		font-size: 16px;
	}
	&:hover,
	&:focus {
		color: $neutral-3-2;
		background-color: $primary-1-7;
	}
}
