.circle-progress {
  overflow: hidden;
  &:hover .slider.large .button-next.open-left,
  &:hover .slider.large .button-next.open-right,
  &:hover .slider.compact .button-next.open-left,
  &:hover .slider.compact .button-next.open-right {
      opacity: 1;
      transform: translateX(0);
  }
  .slider {
      padding: 0 50px;
      @include bp($md) {
          padding: 0 100px;
      }
      &.compact,
      &.large {
          .button-next {
              position: absolute;
              top: 50px;
              @include bp($md) {
                  top: calc(50% - 30px);
              }
              @include bp($lg) {
                  top: calc(50% - 35px);
                  &.open-left.hover {
                      transform: translateX(-100%);
                      opacity: 0;
                      pointer-events: none;
                  }
              }
              &.open-left {
                  left: 0;
                  @include bp($lg) {
                      opacity: 0;
                      transition:
                          opacity 0.3s,
                          transform 0.3s;
                  }
                  @include bp($xxl) {
                      transform: translateX(-100%);
                  }
              }
              &.open-right {
                  right: 0;
                  @include bp($lg) {
                      opacity: 0;
                      transition:
                          opacity 0.3s,
                          transform 0.3s;
                  }
                  @include bp($xxl) {
                      transform: translateX(100%);
                  }
              }
          }
          img {
              border-radius: 50%;
              width: 150px;
              height: 150px;
              z-index: 0;
              object-fit: cover;
              object-position: center;
          }
          .inner,
          .svg-divider,
          .image-1,
          .image-2,
          .image-3 {
              opacity: 1;
              transition: opacity 0.75s;
              will-change: opacity;
          }
          .opacity {
              opacity: 0;
          }
          .image-1.opacity {
              opacity: 1;
              @include bp($xl) {
                  opacity: 0;
              }
          }
          .image-3 {
              right: 100px;
          }
          .image-fill-left,
          .image-fill-right {
              opacity: 0;
          }
          .image-fill-right {
              left: 100px;
          }
          .left {
              transition: 0.75s right cubic-bezier(0.215, 0.61, 0.355, 1);
              will-change: right;
              z-index: 1;
              opacity: 1;
          }
          .right {
              transition: 0.75s left cubic-bezier(0.215, 0.61, 0.355, 1);
              will-change: left;
              z-index: 1;
              opacity: 1;
          }
          .inner {
              width: 80%;
              @include bp($md) {
                  width: calc(100% - 265px);
                  height: 200px;
              }
          }
      }
      &.compact {
          max-width: 1000px;
          box-sizing: border-box;
          .image-2 {
              right: 100px;
          }
          .image-3 {
              width: 0;
              height: 0;
          }
          .image-fill-left {
              right: 100px;
          }
          .left {
              @include bp($xl) {
                  right: calc(100% - 250px);
              }
          }
          .right {
              @include bp($xl) {
                  left: calc(100% - 250px);
              }
          }
          .inner {
              @include bp($xl) {
                  width: calc(90% - 415px);
              }
          }
      }
      &.large {
          box-sizing: content-box;
          max-width: 1400px;
          .image-2 {
              right: 300px;
          }
          .image-fill-left {
              right: 300px;
          }
          .left {
              @include bp($xl) {
                  right: calc(100% - 250px);
              }
          }
          .right {
              @include bp($xl) {
                  left: calc(100% - 450px);
              }
          }
          .inner {
              @include bp($xl) {
                  width: calc(92% - 565px);
              }
              @include bp($xxl) {
                  width: calc(80% - 565px);
              }
          }
      }
      .percentage {
          height: 1px;
          position: relative;
          span {
              position: absolute;
              height: 2px;
              left: 0;
          }
      }
  }
}
