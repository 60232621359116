/*------------------------------------*\
	# HEADINGS
\*------------------------------------*/

[class*="f-display-"],
h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	color: color__(text-headings);
	margin: 0;

	// Headings should inherit colour from wrappers or utility classes
	color: currentColor;
	font-family: $font__sans-serif-headings;
}

[class*="f-display-"],
h1,
h2,
h3,
h4 {
	text-transform: none; // If this is defaulted to uppercase it could be confusing on builds.
	font-weight: normal;
}

[class*="f-display-"],
[class*="f-display-"] * {
	font-weight: 600;
}

.f-display-1,
.f-display-1 * {
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(34);

	@include bp($md) {
		font-weight: 600;
		@include font-size(68);
	}
}

.f-display-2,
.f-display-2 * {
	text-transform: capitalize;
	letter-spacing: 0.03em;
	@include font-size(30);

	@include bp($md) {
		@include font-size(34);
	}

	@include bp($xl) {
		@include font-size(40);
	}
}

.f-display-3,
.f-display-3 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(22);

	@include bp($md) {
		@include font-size(27);
	}

	@include bp($xl) {
		@include font-size(33);
	}
}

.f-display-4,
.f-display-4 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(22);

	@include bp($md) {
		@include font-size(23);
	}

	@include bp($xl) {
		@include font-size(28);
	}
}

.f-display-5,
.f-display-5 * {
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(18);

	@include bp($md) {
		@include font-size(20);
	}

	@include bp($xl) {
		@include font-size(22);
	}
}

.f-display-6,
.f-display-6 * {
	text-transform: capitalize;
	letter-spacing: 0.03em;
	@include font-size(14);

	@include bp($md) {
		@include font-size(16);
	}

	@include bp($xl) {
		@include font-size(18);
	}
}

.f-display-7,
.f-display-7 * {
	letter-spacing: 0.03em;
	font-weight: 600;
	@include font-size(30);

	@include bp($md) {
		@include font-size(34);
	}

	@include bp($xl) {
		@include font-size(40);
	}
}

.f-display-8,
.f-display-8 * {
	letter-spacing: 0.03em;
	font-weight: 600;
	@include font-size(30);

	@include bp($md) {
		@include font-size(40);
	}

	@include bp($xl) {
		@include font-size(50);
	}
}

.f-display-9,
.f-display-9 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(22);

	@include bp($md) {
		@include font-size(27);
	}

	@include bp($xl) {
		@include font-size(33);
	}

	@include bp($xxl) {
		@include font-size(36);
	}
}

.f-display-10,
.f-display-10 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(16);
}

.f-display-11,
.f-display-11 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(14);

	@include bp($md) {
		@include font-size(16);
	}
}

.f-display-12,
.f-display-12 * {
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(16);

	@include bp($md) {
		@include font-size(18);
	}

	@include bp($xl) {
		@include font-size(20);
	}
}

.f-display-13,
.f-display-13 * {
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(16);

	@include bp($md) {
		@include font-size(20);
	}

	@include bp($xl) {
		@include font-size(28);
	}
}

.f-display-14,
.f-display-14 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(22);

	@include bp($md) {
		@include font-size(25);
	}

	@include bp($xl) {
		@include font-size(28);
	}

	@include bp($xxl) {
		@include font-size(36);
	}
}

.f-display-15,
.f-display-15 * {
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(16);

	@include bp($md) {
		@include font-size(18);
	}

	@include bp($xl) {
		@include font-size(22);
	}
}

.f-display-16,
.f-display-16 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(18);

	@include bp($md) {
		@include font-size(23);
	}

	@include bp($xl) {
		@include font-size(28);
	}
}

.f-display-17,
.f-display-17 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(14);

	@include bp($md) {
		@include font-size(15);
	}
}

.f-display-18,
.f-display-18 * {
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(22);
}

.f-display-19,
.f-display-19 * {
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(20);

	@include bp($md) {
		@include font-size(23);
	}

	@include bp($xl) {
		@include font-size(28);
	}
}
