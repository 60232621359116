.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	overflow: auto;
	transform: translateX(100%);
	scroll-behavior: smooth;
	:target {
		scroll-margin-top: 48.781px;
		@include bp($lg) {
			scroll-margin-top: 72.781px;
		}
	}
	.close,
	.close-three {
		cursor: pointer;
	}
	> * {
		opacity: 0;
	}
	&.visible {
		> * {
			opacity: 1;
		}
	}
	&.transition {
		will-change: transform;
		transition: transform 0.5s cubic-bezier(0.66, 0.01, 0.27, 0.84);
		> * {
			will-change: opacity;
			transition: opacity 0.5s cubic-bezier(0, 0.25, 0.5, 0.5);
		}
	}
	&.transition-two {
		will-change: transform;
		transition: transform 0.5s cubic-bezier(0.66, 0.01, 0.27, 0.84);
	}
	&.fixed {
		transform: translateX(0);
		> * {
			opacity: 1;
		}
	}
	&.fixed-two {
		transform: translateX(0);
	}
	&.single {
		transform: translateX(0);
		position: static;
		> * {
			opacity: 1;
		}
		nav a {
			color: inherit;
			text-decoration: none;
			&:hover,
			&:focus {
				color: $primary-1-5;
			}
		}
		:target {
			scroll-margin-top: 75px;
			@include bp($md) {
				scroll-margin-top: 200px;
			}
		}
	}
	&.team-overlay {
		.close {
			svg path {
				fill: $primary-1-1;
				transition: 0.2s ease-in-out fill;
			}
			&:hover svg path,
			&:focus svg path {
				fill: $primary-1-5;
			}
		}
		.position-sticky {
			background-color: rgba($tertiary-1-3, 0.75);
			z-index: 999;
		}
		img {
			width: 100%;
			max-height: 45vh;
			object-fit: cover;
			object-position: top;
			@include bp($sm) {
				width: 50%;
				max-width: 350px;
			}
		}
		a {
			text-decoration: none;
		}
		.icons {
			svg path {
				fill: $primary-1-5;
				transition: 0.2s ease-in-out fill;
			}
			&:hover svg path,
			&:focus svg path {
				fill: $primary-1-1;
			}
		}
		.height {
			height: calc(100vh - 48.7812px);
			@include bp($sm) {
				height: calc(100vh - 72.7812px);
			}
		}
	}
	&.what-we-do-overlay {
		.close {
			svg path {
				fill: $primary-1-1;
				transition: 0.2s ease-in-out fill;
			}
			&:hover svg path,
			&:focus svg path {
				fill: $primary-1-5;
			}
		}
		.position-sticky {
			background-color: rgba($white, 0.75);
			z-index: 999;
		}
		.full-half {
			width: 100%;
			max-height: 270px;
			object-fit: cover;
			@include bp($sm) {
				width: 50%;
			}
		}
		.cover {
			object-fit: cover;
		}
		.line {
			border-bottom: 1px solid $primary-2-6;
		}
		.cta {
			border-bottom: 1px solid $primary-1-5;
		}
	}
	&.products-overlay {
		.close {
			svg path {
				fill: $primary-1-1;
				transition: 0.2s ease-in-out fill;
			}
			&:hover svg path,
			&:focus svg path {
				fill: $primary-1-5;
			}
		}
		.position-sticky {
			background-color: rgba($white, 0.75);
			z-index: 999;
		}
		.full-half {
			width: 100%;
			max-height: 270px;
			object-fit: cover;
			@include bp($sm) {
				width: 50%;
			}
		}
		.line {
			border-bottom: 1px solid $primary-2-6;
		}
		.line-top {
			border-top: 1px solid $primary-1-10;
		}
		.references {
			border-top: 1px solid $primary-2-6;
		}
		.cta {
			.cover {
				width: 100%;
				object-fit: cover;
			}
			&.standard {
				.direction {
					flex-direction: row-reverse;
					@include bp($sm) {
						.cover {
							width: 45.83333333%;
						}
					}
					.content {
						width: 100%;
						@include bp($sm) {
							border-bottom: 1px solid $primary-1-5;
							width: 48%;
						}
						@include bp($xl) {
							width: 42%;
						}
						.paragraph {
							border-left: 1px solid $primary-1-5;
							@include bp($sm) {
								border-left: initial;
								padding-bottom: 3rem;
							}
						}
					}
				}
			}
			&.divided {
				.direction {
					align-items: center;
					@include bp($sm) {
						.cover {
							width: 40%;
							max-height: 222px;
						}
					}
					.content {
						width: 100%;
						@include bp($sm) {
							width: 55%;
							border-left: initial;
						}
						.paragraph {
							border-left: 1px solid $primary-1-5;
							@include bp($sm) {
								border-left: initial;
							}
							@include bp($lg) {
								margin-right: 3rem;
							}
						}
					}
				}
			}
		}
		.list {
			ul,
			ol {
				li {
					border-bottom: 1px solid $primary-1-5;
				}
			}
			&.standard {
				ul {
					list-style-type: none;
				}
			}
			&.sub-list {
				ol {
					li:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
	.product-form {
		h2 {
			font-size: inherit;
		}
		div[role="img"] {
			background-size: cover;
			background-position: center;
			position: relative;
			overflow: hidden;
			z-index: 0;
			&::after {
				position: absolute;
				content: "";
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				background-color: rgba($primary-1-5, 0.7);
			}
			.curveOne {
				position: relative;
				overflow: hidden;
				&::before {
					content: "";
					position: absolute;
					border-bottom-right-radius: 100% 50%;
					border-right: 50rem solid $primary-1-5;
					right: -50rem;
					bottom: -0.5rem;
					left: 0;
					top: 65%;
					z-index: -1;
					@include bp($md) {
						border-bottom-right-radius: 40% 75%;
						border-right: 21rem solid $primary-1-5;
						top: 0;
						right: -20rem;
						bottom: -20rem;
						left: -60rem;
					}
				}
			}
			@include bp($xl) {
				.curveTwo {
					border-bottom-right-radius: 20% 40%;
				}
			}
		}
		form {
			max-width: 500px;
			margin: auto;
		}
		.gfield_label {
			display: none;
		}
		.gform_confirmation_message {
			color: $primary-1-5;
		}
		.gfield_validation_message,
		input[type="text"]::placeholder,
		input[type="email"]::placeholder,
		textarea::placeholder,
		label {
			color: $primary-1-2;
			font-style: inherit;
			font-family: inherit;
		}
		input[type="text"],
		input[type="email"],
		textarea,
		select {
			background-color: transparent;
			color: $primary-1-2;
			border: 1px solid $primary-2-6;
		}
		input[type="text"]:hover,
		input[type="email"]:hover,
		select:hover,
		textarea:hover,
		input[type="text"]:focus,
		input[type="email"]:focus,
		select:focus,
		textarea:focus {
			background-color: transparent;
			color: $primary-1-2;
			border: 1px solid $primary-2-6;
		}
		select {
			background-image: url(../assets/img/icons/chevron.svg);
		}
		textarea {
			resize: none;
		}
	}
	&.navigation-overlay {
		background-color: $primary-2-1;
		overflow: auto;
		.main-menu {
			width: 95%;
			ul {
				margin: 0;
				padding: 0;
				li {
					margin: 0.5rem 0 0 0;
					padding: 0 0 0.75rem 0;
					border-bottom: $primary-2-6 solid 1px;
					@include bp($md) {
						padding: 0 0 1.25rem 0;
					}
					&:first-child {
						margin: 0;
					}
					a {
						color: inherit;
						text-decoration: none;
						&:hover,
						&:focus {
							color: $primary-1-1;
						}
					}
				}
			}
		}
		.sub-menu {
			width: 40%;
			border-top: $primary-2-6 solid 1px;
			ul {
				margin: 0;
				padding: 0;
				li {
					margin: 0;
					padding: 0.75rem 0 0 0;
					@include bp($md) {
						padding: 1.25rem 0 0 0;
					}
					a {
						color: inherit;
						text-decoration: none;
						&:hover,
						&:focus {
							color: $primary-1-1;
						}
					}
				}
			}
		}
		.close-three {
			width: calc(75px - 1.25rem);
			height: calc(75px - 2.25rem);
			@include bp($md) {
				width: calc(102px - 1.25rem);
				height: calc(102px - 2.25rem);
			}
			svg path {
				fill: $primary-1-5;
				transition: 0.2s ease-in-out fill;
			}
			&:hover svg path,
			&:focus svg path {
				fill: $primary-1-1;
			}
		}
	}
	&.video-container-overlay {
		overflow: hidden;
		background-color: $primary-1-5;
		.wrapper {
			position: relative;
			width: 100%;
			padding-top: 100vh;
			background-size: cover;
			@include bp(orientation portrait) {
				padding-top: 56.25%;
			}
			.video-header {
				position:  absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				@include bp(orientation landscape) {
					object-fit: cover;
					height: 100%;
				}
			}
		}
		.controls {
			display: flex;
			justify-content: space-between;
			position: relative;
			bottom: 0;
			@include bp(orientation landscape) {
				bottom: 100px;
			}
			div {
				display: flex;
				width: 100%;
				justify-content: center;
				padding-bottom: 1.25rem;
				@include bp(orientation landscape) {
					width: 50%;
					&.left {
						justify-content: flex-start;
						padding-bottom: initial;
					}
					&.right {
						justify-content: flex-end;
					}
				}
			}
		}
	}
}

.fade {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	&.transition {
		will-change: opacity;
		transition: opacity 0.5s cubic-bezier(0.03, 0.5, 0.25, 0.6);
	}
	&.fixed {
		opacity: 1;
		z-index: 999;
	}
	&.modal-overlay {
		background-color: $primary-1-5;
		.modals {
			cursor: pointer;
			svg path {
				fill: $primary-1-5;
				transition: 0.2s ease-in-out fill;
			}
			&:hover span svg path,
			&:focus span svg path {
				fill: $primary-1-1;
			}
			span {
				transform: translateY(-100%);
				@include bp(orientation landscape) {
					transform: translateY(0);
				}
				svg path {
					fill: darken($primary-1-1, 10);
				}
				svg:hover path,
				svg:focus path {
					fill: $primary-1-1;
				}
			}
			img {
				object-fit: contain;
				max-height: 100vh;
				width: initial;
			}
		}
	}
}
