.heading-image-single-card {
    svg {
        width: 30px;
        height: 30px;
        g {
            fill: $primary-1-5;
        }
    }
    img {
        max-height: 790px;
        object-fit: cover;
        width: 100%;
    }
}
