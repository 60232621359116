$grid-columns: 48 !default;
$grid-gutter-width: 0 !default;
$grid-row-columns: 6 !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  // 4px
  2: $spacer * 0.5,
  // 8px
  3: $spacer * 0.75,
  // 12px
  4: $spacer * 1,
  // 16px
  5: $spacer * 1.25,
  // 20px
  6: $spacer * 1.5,
  // 24px
  7: $spacer * 1.75,
  // 28px
  8: $spacer * 2.125,
  // 34px
  9: $spacer * 3,
  // 48px
  10: $spacer * 3.75,
  // 60px
  11: $spacer * 4.375,
  // 70px
  12: $spacer * 6.5,
  // 104px
  13: $spacer * 8.75,
  // 140px
  14: $spacer * 10,
  // 160px
  15: $spacer * 12.5,
  // 200px
  16: $spacer * 15 // 240px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1030px,
  xl: 1296px,
  xxl: 1696px,
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
  xxl: 1440px,
) !default;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1240px;
$xxl: 1440px;

//colors
$grey: #707070;
$primary-1-1: #5e9f69;
$primary-1-2: #a0b8ae;
$primary-1-3: #a7c4a7;
$primary-1-4: #33735b;
$primary-1-5: #005133;
$primary-1-6: #005330;
$primary-1-7: #7eb287;
$primary-1-8: #005b3b;
$primary-1-9: #235035;
$primary-1-10: #c4d7c5;

$primary-2-1: #dfece1;
$primary-2-2: #003e27;
$primary-2-3: #c4d7c5;
$primary-2-4: #003d28;
$primary-2-5: #669684;
$primary-2-6: #46715c;
$primary-2-7: #ccdcd6;
$primary-2-8: #002619;
$primary-2-9: #eef5f0;
$primary-2-10: #99b9ad;
$secondary-1-1: #e9dcdf;
$secondary-1-2: #d3babf;
$secondary-1-3: #9e777f;
$secondary-1-4: #824451;
$secondary-1-5: #915360;
$secondary-1-6: #dad1e3;
$secondary-1-7: #d3c8de;
$secondary-1-8: #856f9e;
$secondary-1-9: #81709b;
$secondary-2-2: #b3846f;
$secondary-2-3: #dfb681;
$secondary-2-4: #cca593;
$secondary-2-5: #cca593;
$secondary-2-6: #cc7512;
$secondary-2-7: #ab8672;

$neutral-2-1: #f3f0f6;
$neutral-2-2: #e6e0ed;
$neutral-2-3: #fcf2e8;
$neutral-2-4: #70708f;
$neutral-2-5: #e5edea;
$neutral-2-6: #729585;
$neutral-3-1: #faf8f0;
$neutral-3-2: #f4edef;
$neutral-3-3: #b6989f;
$neutral-3-4: #c1b2d1;
$neutral-3-5: #e7ddb4;
$tertiary-1-1: #5d849c;
$tertiary-1-2: #e2eaee;
$tertiary-1-3: #f0f4f6;
$tertiary-1-4: #4a697c;

$transparent: transparent;

$accordion-button-color: $transparent !default;
$accordion-button-bg: $transparent !default;

$accordion-button-active-bg: $transparent !default;
$accordion-button-active-color: $transparent !default;

$accordion-color: $transparent !default;
$accordion-bg: $transparent !default;

$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 15 15' width='15' height='15'%3E%3Cpath fill='%2399a4ad' d='M7 15V8H0V7h7V0h1v7h7v1H8v7H7z'/%3E%3C/svg%3E%0A") !default;
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 15 15' width='15' height='15'%3E%3Cpath fill='%2399a4ad' d='M15 7v1H0V7h15z'/%3E%3C/svg%3E%0A") !default;
