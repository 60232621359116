.two-col-center-image-slider {
    &.center-image {
        width: 100%;
        position: absolute;
        @include bp($sm) {
           
            width: 78%;
            right: 0;
            left: 0;
            top: calc(100% - 120px);
        }
        .button-next {
            top: calc(50% - 25px);
            z-index: 990;
            @include bp($md) {
                top: calc(50% - 35px);
            }
        }
        .slide-left {
            left: 0;
            @include bp($sm) {
                transform: translateX(-20px);
            }
            @include bp($lg) {
                transform: translateX(-125%);
            }
        }
        .slide-right {
            right: 0;
            @include bp($sm) {
                transform: translateX(20px);
            }
            @include bp($lg) {
                transform: translateX(125%);
            }
        }
        .count {
            width: 0;
            height: 0;
            img {
                opacity: 0.5;
                transition: opacity 0.5s;
            }
            &.visible {
                background-color: $primary-1-5;
                width: initial;
                height: initial;
                border: 1px solid $primary-1-5;
                position: relative;
                cursor: zoom-in;
                img {
                    position: absolute;
                    opacity: 1;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
                &:before {
                    content: "";
                    display: block;
                    padding-bottom: percentage(1240/1754);
                }
            }
        }
    }
    &.background {
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        z-index: -1;
        padding: 45%;
        @include bp($sm) {
            padding: 30%;
        }
    }
}
