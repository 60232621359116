/*------------------------------------*\
	# TYPOGRAPHY MASTER
\*------------------------------------*/

body,
button,
input,
select,
textarea {
	color: color__(text-main);
	font-family: $font__main;

	font-size: 16px;
	line-height: 1.5;
}

.leading {
	line-height: 1.34;
}

h2.leading {
	@include bp($sm) {
		line-height: 1.5;
	}
}

@import "headings";
@import "body";
@import "links";
@import "lists";
