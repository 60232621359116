/*------------------------------------*\
	# FLEXBOX TOOLS
	@see https://tailwindcss.com/docs/flex-direction
\*------------------------------------*/

.flex {
	display: flex;
}

.flex-row {	
	flex-direction: row;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-col {
	flex-direction: column;
}

.flex-col-reverse {
	flex-direction: column-reverse;
}



/* = ALIGNMENT
@see https://tailwindcss.com/docs/justify-content
----------------------------------------------- */

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}


.items-start {
	align-items: flex-start;
}

.items-end {
	align-items: flex-end;
}

.items-center {
	align-items: center;
}

.items-baseline {
	align-items: baseline;
}

.items-stretch {
	align-items: stretch;
}
