/*------------------------------------*\
	# SHAME, SHAME, SHAME!
	- Just kidding it's just a good place to dump things
	when you don't know here they go yet.
\*------------------------------------*/

.terms {
	h1 {
		padding-left: 60px
	}
	hr {
		margin-bottom: 30px; 
		margin-top: 14px;
	}
	.text-bold {
		font-weight: 700;
	}
	.privacy-bullet {
		margin-bottom: 0.5rem;
		margin-top: 1rem;
	}
	.privacy-sub-bullet {
		margin-top: 0.75rem;
		margin-bottom: 0.325rem;
		margin-left: 0.5rem;
	}
	a {
		color: inherit;
		&:hover {
			text-decoration: underline dotted;
		}
	}
}
