.heading-list-cards {
    h2 {
        margin-bottom: 0;
    }
    svg {
        width: 30px;
        height: 30px;
        g {
            fill: $primary-1-5;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            margin: 0 0 0.75rem 0;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid $primary-2-6;
        }
    }
}
