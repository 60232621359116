.teams-cards {
    img {
        transition: 0.2s linear transform;
        transform: scale(1);
    }
    .item {
        cursor: pointer;
        &:hover,
        &:focus {
            img {
                transform: scale(1.05);
            }
            ~ div .button-arrow,
            .button-arrow {
                color: $primary-1-5;
                svg path {
                    fill: $primary-1-5;
                }
            }
        }
    }
}
