/*------------------------------------*\
	# FORMS MASTER
\*------------------------------------*/

// Form Variables
$input-radius:        4px;
$button-radius:       4px;
$input-padding:       12px 16px;
$field_space:         16px;
$radio-size:          16px;
$radio-inner-size:    8px;
$check-size:          15px;
$fixed-prefix-size:   110px;

// Colours
$color__input-bg:                 color__(white);
$color__form-border:              color__(black);

$color__checkbox-tick:            color__(black);
$color__radio-dot:                color__(black);
$color__input-checked-bg:         color__(white);

$color__text-input:               color__(text-main);
$color__text-input-background:    color__(white);
$color__text-input-focus:         darken(color__(text-main), 33%);
$color__text-input-focus-bg:      color__(white);
$color__text-input-placeholder:   color__(black);

$color__select-focus-border:      color__(black);
$color__select-text:              color__(black);

$color__li-hover-label:           color__(black);

$color__gf-description:           color__(black);
$color__gf-section-border:        color__(black);

$color__date-picker-bg:           color__(white);

$color__confirmation-bg:          color__(white);



button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
	font-family: $font__sans-serif;
}

form ul,
form ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

form li {
	display: block;
	margin: 0;
	padding: 0;
}

form {
	@include font-size(15);
}

#input_3_25 {
	height: 120px;
}

@import "labels";
@import "fields";
@import "gravity-forms";
@import "gravity-forms-ready-classes";