/*------------------------------------*\
	# ICONS
\*------------------------------------*/

.icon {
	display: block;
	width: 40px;
	height: 40px;

	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	// Never shrink me!
	flex-shrink: 0;

	&--mirror {
		transform: scaleX(-1);
		transform-origin: center center;
	}

	&--down {
		// All icons start pointing right
		transform: rotate(90deg);
		transform-origin: center center;
	}

	&--up {
		// All icons start pointing right
		transform: rotate(-90deg);
		transform-origin: center center;
	}

	&--currentColor svg * {
		fill: currentColor;
	}

	&--currentColor-stroke svg * {
		stroke: currentColor;
	}

}

.icon svg {
	width: 100%;
	height: 100%;

	flex: 0 0 auto;
}

.icon path {
	transition: 0.2s ease-in-out fill,
	0.2s ease-in-out stroke;
}



/* = ICON EXTERNAL
----------------------------------------------- */

.icon-external-link {
	width: 16px;
	height: 16px;
}

[class*="c-button-"] .icon-external-link {
	margin-top: -0.2em;
}

[class*="c-button-"] .icon-external-link svg {
	width: 0.9em;
	height: 0.9em;
}