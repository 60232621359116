/*------------------------------------*\
	# TYPOGRAPHY SETTINGS
\*------------------------------------*/


@import "fonts";

// Pre line height.
$font__line-height-pre: 1.6;

