.three-col-checklist {
    &.extra {
        position: relative;
        z-index: 0;
        &:before {
            content: "";
            position: absolute;
            background-image: url(../assets/img/icons/shape-three.svg);
            background-size: cover;
            bottom: -190px;
            right: 0;
            width: calc(310px/2);
            height: 230px;
            z-index: -1;
            @include bp($sm) {
                bottom: -400px;
                width: calc(620px/2);
                height: 460px;
            }
        }
    }
    svg {
        width: 42px;
        height: 42px;
        g {
            fill: $primary-1-1;
        }
    }
    .before {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            border-top: 1px solid $primary-2-3;
            top: -5px;
            right: -2.5rem;
            left: -2.5rem;
            @include bp($sm) {
                right: 0;
                left: 0;
            }
        }
    }
    ul {
        list-style: none;
        li {
            margin-right: 0;
            border-bottom: 1px solid $primary-2-3;
            svg {
                margin-right: 1rem;
                margin-left: 0.5rem;
                width: 15px;
                height: 15px;
                circle {
                    fill: $primary-1-1;
                }
            }
        }
    }
}
