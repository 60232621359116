.what-we-do {
    .large-cards {
        .cover {
            object-fit: cover;
        }
        .item {
            cursor: pointer;
            &:hover,
            &:focus {
                ~ div .button-arrow-circle,
                .button-arrow-circle {
                    background-image: url(../assets/img/icons/arrow-circle.svg);
                }
            }
        }
    }
    .small-cards {
        position: relative;
        z-index: 1;
        svg path {
            fill: $primary-1-5;
            opacity: 0;
            transition: ease-in-out opacity 0.4s;
        }
        a {
            opacity: 1;
            transition: ease-in-out opacity 0.4s, ease-in-out transform 0.3s, ease-in-out color 0.3s;
            transform: translateX(0);
        }
        &:hover,
        &:focus {
            cursor: pointer;
            transition: 0.3s ease-in-out  background-color;
            background-color: $primary-2-7;
            a {
                opacity: 0;
                transform: translateX(100%);
            }
            svg path {
                opacity: 1;
            }
        }
    }
}
