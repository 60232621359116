.single-video {
  &.extra {
    position: relative;
    .shape-wrapper {
      position: absolute;
      overflow: hidden;
      right: 0;
      background-image: url(../assets/img/icons/shape-four.svg);
      background-size: cover;
      bottom: calc(-245px/2);
      width: 18%;
      max-width: 348px;
      height: 245px;
      z-index: 0;
      @include bp($sm) {
        height: 490px;
        bottom: calc(-490px/2);
      }
    }
  }
  &.bottom {
    .shape-wrapper {
      bottom: calc(-245px/4);
    }
  }
  .wrapper {
    position: relative;
    z-index: 0;
    padding-top: 56.25%;
    width: 100%;
    background-size: cover;
    background-position: center top;
    .video-single {
      position: absolute;
      object-fit: cover;
      object-position: center top;
      top: -1px;
      right: -1px;
      bottom: 0;
      left: -1px;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
    }
    .controlsSingle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &:hover .button-play.display,
      &:focus .button-play.display {
        display: block;
      }
      .button-play {
        &.display {
          display: none;
        }
        &.display-mobile {
          display: none;
        }
        @include bp($sm) {
          width: 70px;
          height: 70px;
          background-size: 20px;
        }
        @include bp($lg) {
          width: 116px;
          height: 116px;
          background-size: 25px;
        }
      }
    }
  }
}
