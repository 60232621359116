.heading-cards {
    h2 {
        margin-bottom: 0;
    }
    .before {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            border-top: 1px solid $tertiary-1-1;
            top: 0;
            right: -2.5rem;
            left: -2.5rem;
            @include bp($sm) {
                right: 0;
                left: 0;
            }
        }
    }
    svg {
        width: 30px;
        height: 30px;
        g {
            fill: $primary-1-5;
        }
    }
}
