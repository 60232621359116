/*------------------------------------*\
	# SITE HEADER
\*------------------------------------*/

.site-header {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}
}

.main-navigation {
	position: relative;
	z-index: 998;
	nav {
		position: absolute;
		right: 0;
		left: 0;
		z-index: 999;
		border-bottom: 1px solid rgba($white, 0.3);
		.navbar-toggler {
			cursor: pointer;
			border-left: 1px solid rgba($white, 0.3);
			transition: 0.2s ease-in-out background-color;
			svg.menu {
				width: 75px;
				height: 75px;
				transition: height 0.5s, width 0.5s;
				@include bp($md) {
					width: 102px;
					height: 102px;
				}
			}
		}
		a svg {
			max-width: 100%;
			width: 120px;
			transition: height 0.5s, width 0.5s;
			@include bp($md) {
				width: 180px;
			}
		}
	}
	nav ~ div {
		position: relative;
		z-index: 998;
		&.default {
			.background {
				@include bp($md) {
					background-color: initial;
					h1 {
						text-shadow: 0 3px 6px transparentize($black, 0.84);
					}
				}
			}
			div[role="img"] {
				position: absolute;
				top: 0;
				right: 0;
				height: 264px;
				left: 0;
				z-index: -1;
				background-size: cover;
				background-position: center;
				@include bp($sm) {
					height: 344px;
				}
				@include bp($md) {
					height: initial;
					bottom: 0;
				}
			}
		}
	}
	&.contact {
		.navbar-toggler:hover {
			background-color: $primary-1-8;
		}
		.background {
			background-color: $primary-1-8;
		}
	}
	&.solutions,
	&.involved {
		.navbar-toggler:hover {
			background-color: $primary-1-4;
		}
		.background {
			background-color: $primary-1-4;
		}
	}
	&.process {
		.navbar-toggler:hover {
			background-color: $primary-1-5;
		}
		.background {
			background-color: $primary-1-5;
		}
	}
	&.what {
		.navbar-toggler:hover {
			background-color: $secondary-1-5;
		}
		.background {
			background-color: $secondary-1-5;
		}
	}
	&.team {
		.navbar-toggler:hover {
			background-color: $tertiary-1-1;
		}
		.background {
			background-color: $tertiary-1-1;
		}
	}
	&.about {
		.navbar-toggler:hover {
			background-color: $secondary-2-6;
		}
		.background {
			background-color: $secondary-2-6;
		}
	}
	&.home {
		height: 100%;
		.navbar-toggler:hover {
			background-color: $primary-1-5;
		}
		&:before {
			position: absolute;
			content: "";
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($primary-1-5, 0.8);
			z-index: 1;
		}
		.clover svg {
			width: 35px;
			height: 35px;
			g {
				fill: $primary-1-1;
			}
		}
		h2 {
			margin: 0;
		}
		.video-small {
			position: absolute;
			top: 0;
			right: 0;
			height: 413px;
			left: 0;
			z-index: 0;
			background-color: $primary-1-5;
			@include bp($md) {
				height: 100vh;
			}
			.video-small-header {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}
	&.basic {
		position: sticky;
		top: 0;
		border-bottom: 1px solid $secondary-1-6;
		nav {
			position: initial;
			transition: height 0.5s;
			height: 102px;
			background-color: $neutral-2-1;
			.navbar-toggler {
				border-left: 1px solid $secondary-1-6;
				&:hover {
					background-color: $secondary-1-6;
				}
			}
			svg.slogan path {
				fill: $primary-1-5;
			}
			svg.menu {
				width: 102px;
				height: 102px;
				transition: height 0.5s, width 0.5s;
				line {
					stroke: $primary-1-5;
				}
			}
			svg.logo-default {
				transition: height 0.5s, width 0.5s;
				max-width: 100%;
				width: 180px;
				height: 50px;
			}
		}
	}
	&.down {
		nav {
			height: 75px;
			@include bp($md) {
				height: 90px;
			}
			svg.menu {
				width: 75px;
				height: 75px;
				@include bp($md) {
					width: 90px;
					height: 90px;
				}
			}
			svg.logo-default {
				width: 120px;
				@include bp($md) {
					width: 150px;
				}
			}
		}
	}
}

.sticky-navigation {
	position: fixed;
	z-index: 997;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	overflow: hidden;
	background-color: $neutral-2-1;
	.navbar-toggler {
		cursor: pointer;
		transition: 0.2s ease-in-out background-color;
		border-left: 1px solid $secondary-1-6;
		&:hover {
			background-color: $secondary-1-6;
		}
		svg.menu {
			width: 75px;
			height: 75px;
			transition: height 0.5s, width 0.5s;
			@include bp($md) {
				width: 90px;
				height: 90px;
			}
			line {
				stroke: $primary-1-5;
			}
		}
	}
	svg.slogan path {
		fill: $primary-1-5;
	}
	svg.logo-default {
		max-width: 100%;
		width: 120px;
		transition: width 0.5s;
		@include bp($md) {
			width: 150px;
		}
	}
	&.transition {
		transition: height 0.5s;
	}
	&.down {
		border-bottom: 1px solid $secondary-1-6;
		height: 75px;
		@include bp($md) {
			height: 90px;
		}
	}
}

/* = CLOVER
----------------------------------------------- */

.main-navigation.home .header-clover-cont {
	position: relative;
	background-color: $primary-1-5;
	@include bp($md) {
		background-color: transparent;
		height: calc(100vh - 104px);
	}
}

.main-navigation.home .header-clover-wrapper {
	position: absolute;
	overflow: hidden;
	right: 0;
	top: -54.5px;
	width: calc(110px/2);

	@include bp($md) {
		top: initial;
		bottom: -79px;
		width: calc(160px/2);
		height: 158px;
	}

	@include bp($xl) {
		bottom: -122px;
		width: calc(246px/2);
		height: 244px;
	}
}

.main-navigation.home .header-clover {
	width: 200%;
	animation: clover_rotate 12s ease-in-out infinite reverse;
}

/* = CLOVER LEAVES
..................................*/

[class*="header-clover__leaf-"] {
	animation: 24s linear forwards infinite;
}

.header-clover__leaf-1 {
	fill: $secondary-1-5; // #915360
	animation-name: switch_color_leaf_1;
	animation-delay: 6s;
}

.header-clover__leaf-2 {
	fill: $primary-1-1; // #5e9f69
	animation-name: switch_color_leaf_2;
	animation-delay: 3s;
}

.header-clover__leaf-3 {
	fill: $neutral-3-5; // #e7ddb4
	animation-name: switch_color_leaf_3;
	animation-delay: 9s;
}

.header-clover__leaf-4 {
	fill: $secondary-2-2; // #b3846f
	animation-name: switch_color_leaf_4;
	animation-delay: 12s;
}

/* = CLOVER ANIMATIONS
..................................*/

@keyframes clover_rotate {
	0% {
		transform: rotate(0deg);
	}

	12.5% {
		transform: rotate(90deg);
	}

	25% {
		transform: rotate(90deg);
	}

	37.5% {
		transform: rotate(180deg);
	}

	50% {
		transform: rotate(180deg);
	}

	62.5% {
		transform: rotate(270deg);
	}

	75% {
		transform: rotate(270deg);
	}

	87.5% {
		transform: rotate(360deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes switch_color_leaf_1 {
	0.1% {
		fill: $tertiary-1-1;
	}
	50% {
		fill: $tertiary-1-1;
	}
	50.1% {
		fill: $secondary-1-5;
	}
	100% {
		fill: $secondary-1-5;
	}
}

@keyframes switch_color_leaf_2 {
	0.1% {
		fill: $secondary-1-8;
	}
	50% {
		fill: $secondary-1-8;
	}
	50.1% {
		fill: $primary-1-1;
	}
	100% {
		fill: $primary-1-1;
	}
}

@keyframes switch_color_leaf_3 {
	0.1% {
		fill: $secondary-2-6;
	}
	50% {
		fill: $secondary-2-6;
	}
	50.1% {
		fill: $neutral-3-5;
	}
	100% {
		fill: $neutral-3-5;
	}
}

@keyframes switch_color_leaf_4 {
	0.1% {
		fill: $secondary-1-7;
	}
	50% {
		fill: $secondary-1-7;
	}
	50.1% {
		fill: $secondary-2-2;
	}
	100% {
		fill: $secondary-2-2;
	}
}
