.circle-progress-image-cards {
    .column-heading {
        border-radius: 100px;
    }
    .rows {
        border-bottom: 1px solid $primary-1-10;
        .row-heading {
            width: 34%;
            word-break: break-word;
        }
        .row-item {
            width: 61%;
        }
    }
}
