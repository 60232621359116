.two-col-image-checklist {
    img {
        object-fit: cover;
        object-position: left;
        @include bp($xl) {
            object-position: center;
        }
    }
    ul {
        list-style: none;
        li {
            margin-right: 0;
            border-bottom: 1px solid $primary-2-3;
            &:nth-child(1) {
                border-top: 1px solid $primary-2-3;
            }
            svg {
                margin-right: 1rem;
            }
        }
    }
}

.two-col-image-checklist__image {
    @include bp($md) {
        max-width: none;
    }
}
