.heading-image-cards {
    .outline {
        border: 1px solid $primary-2-5;
    }
    .underline {
        @include bp($sm) {
            border-bottom: 1px solid $primary-2-10;
        }
    }
    svg {
        width: 30px;
        height: 30px;
        g {
            fill: $primary-1-5;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 240px;
        object-position: bottom;
        @include bp($sm) {
            max-height: none;
        }
    }
    &.extra {
        position: relative;
        z-index: -1;
        &:before {
            position: absolute;
            content: "";
            right: 0;
            bottom: -70px;
            background-image: url(../assets/img/icons/shape.svg);
            width: calc(340px/2);
            height: 255px;
            background-size: cover;
            z-index: -1;
            @include bp($sm) {
                bottom: -255px;
                width: calc(680px/2);
                height: 511px;
            }
        }
    }
    &.transparent {
        background-color: rgba($white, 0.25);
    }
}
