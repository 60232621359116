.heading-image-prompt-cards {
    svg {
        width: 30px;
        height: 30px;
        g {
            fill: $primary-1-5;
        }
    }
    img {
        max-height: 790px;
        object-fit: cover;
        object-position: top;
        width: 100%;
    }
    .prompt {
        position: relative;
        .prompt-box {
            position: relative;
            border: 1px solid $neutral-3-4;
            bottom: 50px;
            width: 90%;
            margin: auto;
            @include bp($sm) {
                position: absolute;
                bottom: -3rem;
                width: 268px;
                right: 5%;
            }
            @include bp($md) {
                width: 317px;
            }
            @include bp($xl) {
                width: 415px;
                bottom: -7rem;
            }
            @include bp($xxl) {
                right: initial;
                left: calc(50% + 225px);
            }
        }
    }
}
