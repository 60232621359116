.subscription-form {
    &.extra {
        position: relative;
        z-index: 0;
        &:before {
            content: "";
            position: absolute;
            background-image: url(../assets/img/icons/shape-two.svg);
            background-size: cover;
            background-position: right;
            top: -125px;
            left: 0;
            width: calc(250px/2);
            height: 340px;
            z-index: -1;
            @include bp($sm) {
                top: -250px;
                width: calc(505px/2);
                height: 680px;
            }
        }
    }
    h2 {
        font-size: inherit;
    }
    div[role="img"] {
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        z-index: 0;
        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-color: rgba($secondary-1-5, 0.7);
        }
        .curveOne {
            position: relative;
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                border-bottom-right-radius: 100% 50%;
                border-right: 50rem solid $secondary-1-5;
                right: -50rem;
                bottom: -0.5rem;
                left: 0;
                top: 65%;
                z-index: -1;
                @include bp($md) {
                    border-bottom-right-radius: 40% 75%;
                    border-right: 21rem solid $secondary-1-5;
                    top: 0;
                    right: -20rem;
                    bottom: -20rem;
                    left: -60rem;
                }
            }
        }
        @include bp($xl) {
            .curveTwo {
                border-bottom-right-radius: 20% 40%;
            }
        }
    }
    form {
        max-width: 500px;
        margin: auto;
    }
    .gfield_label {
        display: none;
    }
    .gform_confirmation_message {
        color: $secondary-1-5;
    }
    .gfield_validation_message,
    input[type="text"]::placeholder,
    input[type="email"]::placeholder,
    label {
        color: $secondary-1-6;
        font-style: inherit;
        font-family: inherit;
    }
    input[type="text"],
    input[type="email"],
    input[type="checkbox"],
    input[type="checkbox"]:checked,
    select {
        background-color: transparent;
        color: $secondary-1-6;
        border: 1px solid $neutral-3-3;
    }
    input[type="text"]:hover,
    input[type="email"]:hover,
    select:hover,
    input[type="text"]:focus,
    input[type="email"]:focus,
    select:focus {
        background-color: transparent;
        color: $secondary-1-6;
        border: 1px solid $secondary-2-4;
    }
    select {
        background-image: url(../assets/img/icons/chevron.svg);
    }
    .gfield_checkbox label {
        border: none;
    }
    input[type="checkbox"]:hover ~ label,
    input[type="checkbox"]:focus ~ label {
        background-color: transparent;
        border: none;
    }
    .gfield_checkbox label {
        padding-right: 8px;
    }
    input[type="checkbox"] {
        top: 18px;
        left: 12px;
    }
    input[type="checkbox"]:checked::after {
        border-color: $secondary-1-6;
    }
}
