.image-text {
    overflow: hidden;
    .curve {
        @include bp($md) {
            border-bottom-left-radius: 40% 20%;
        }
    }
    div[role="img"] {
        background-size: cover;
        background-position: top right;
        position: absolute;
        top: 0;
        right: 0;
        height: 240px;
        left: 0;
        z-index: -1;
        @include bp($md) {
            height: initial;
            bottom: 0;
        }
        &::before {
            content: "";
            position: absolute;
            border-bottom-right-radius: 100% 50%;
            border-right: 50rem solid $tertiary-1-1;
            top: 65%;
            right: -50rem;
            bottom: -0.5rem;
            left: 0;
            z-index: -1;
            @include bp($md) {
                border-bottom-right-radius: initial;
                border-right: initial;
                border-bottom-left-radius: 100%;
                border-left: 20rem solid $tertiary-1-1;
                top: 0;
                right: 0;
                bottom: -40rem;
                left: calc(45% - 19.5rem);
            }
        }
    }
}
