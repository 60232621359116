@import "flexbox";

/*------------------------------------*\
	# LAYOUT MASTER
\*------------------------------------*/

/*------------------------------------*\
	# SITE WIDE LAYOUTS
\*------------------------------------*/

.mw-1400 {
	max-width: 1400px;
}

.m-1400 {
	@include bp($xl) {
		max-width: 1496px;
		margin-right: auto;
		margin-left: auto;
	}
}

.m-1200 {
	@include bp($xl) {
		max-width: 1296px;
		margin-right: auto;
		margin-left: auto;
	}
}

.m-900 {
	@include bp($lg) {
		max-width: 900px;
		margin-right: auto;
		margin-left: auto;
	}
}

.m-600 {
	max-width: 600px;
}

.m-1000 {
	max-width: 1000px;
}