/*------------------------------------*\
	# MIXINS MASTER
\*------------------------------------*/

// Rem output with px fallback
@use "sass:math";

@mixin font-size($sizeValue: 16) {
	font-size: $sizeValue + px;
	font-size: math.div($sizeValue, 16) + rem;
}

// Rem output with px fallback. Can be passed any property such as line-height or margin-top.
@mixin rem($property, $sizeValue: 16) {
	#{$property}: $sizeValue + px;
	#{$property}: math.div($sizeValue, 16) + rem;
}

// Center block
@mixin center-block {
	display: block;
	margin: 0 auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}
