/*------------------------------------*\
	# COLOURS
\*------------------------------------*/

/* = MAP COLLECT (used for merging multiple maps)
https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------------- */

@function map-collect($maps...) {
  $collection: ();

  @each $map in $maps {
    $collection: map-merge($collection, $map);
  }

  @return $collection;
}

/* = COLOR DEFINITION - BASE PALETTE
Setup your colors in a map
----------------------------------------------- */

$color-vars-common: (
  /* = COMMON COLORS
  ..................................*/ white: #ffffff,
  black: #000000,
  grey: #707070,
  Primary-1-1: #5e9f69,
  Primary-1-2: #a0b8ae,
  Primary-1-3: #a7c4a7,
  Primary-1-4: #33735b,
  Primary-1-5: #005133,
  Primary-1-6: #005330,
  Primary-1-7: #7eb287,
  Primary-1-8: #005b3b,
  Primary-1-9: #235035,
  Primary-1-10: #c4d7c5,
  Primary-2-1: #dfece1,
  Primary-2-2: #003e27,
  Primary-2-3: #c4d7c5,
  Primary-2-4: #003d28,
  Primary-2-5: #669684,
  Primary-2-6: #46715c,
  Primary-2-7: #ccdcd6,
  Primary-2-8: #002619,
  Primary-2-9: #eef5f0,
  Primary-2-10: #99b9ad,
  Secondary-1-1: #e9dcdf,
  Secondary-1-2: #d3babf,
  Secondary-1-3: #9e777f,
  Secondary-1-4: #824451,
  Secondary-1-5: #915360,
  Secondary-1-6: #dad1e3,
  Secondary-1-7: #d3c8de,
  Secondary-1-8: #856f9e,
  Secondary-1-9: #81709b,
  Secondary-2-2: #b3846f,
  Secondary-2-3: #dfb681,
  Secondary-2-4: #cca593,
  Secondary-2-5: #cca593,
  Secondary-2-6: #cc7512,
  Secondary-2-7: #ab8672,
  Neutral-2-1: #f3f0f6,
  Neutral-2-2: #e6e0ed,
  Neutral-2-3: #fcf2e8,
  Neutral-2-4: #70708f,
  Neutral-2-5: #e5edea,
  Neutral-2-6: #729585,
  Neutral-3-1: #faf8f0,
  Neutral-3-2: #f4edef,
  Neutral-3-3: #b6989f,
  Neutral-3-4: #c1b2d1,
  Neutral-3-5: #e7ddb4,
  Tertiary-1-1: #5d849c,
  Tertiary-1-2: #e2eaee,
  Tertiary-1-3: #f0f4f6,
  Tertiary-1-4: #4a697c,
  black-dark-20: darken(#222222, 20),
  black-dark-25: darken(#222222, 25),
  black-dark-33: darken(#222222, 33)
);

$color-vars-brand: (
  /* = BRAND COLORS
  ..................................*/ // Start Here!
  red-ed1: #ed174c,
  grey-4d4: #4d4d4d,
  blue-217: #21759b
);

$color-vars-social: (
  /* = SOCIAL NETWORK COLORS
  ..................................*/ facebook: #3b5998,
  twitter: #5ea9dd,
  instagram: #683d2f
);

$color-vars-state: (
  /* = STATE COLORS
  ..................................*/ positive: mediumseagreen,
  negative: firebrick
);

/* = COMBINE BASE PALETTE
..................................*/

$color-vars-palette: map-collect($color-vars-common, $color-vars-brand, $color-vars-social, $color-vars-state);

/* = CREATE COLOR FUNCTION - PALETTE 

!![[ For use inside this file only ]]
----------------------------------------------- */

@function color-palette__($color) {
  @return map-get($color-vars-palette, $color);
}

/* = COLOR DEFINITION - APPLIED
Setup your colors in a map
----------------------------------------------- */

$color-vars-applied-theme: (
  /* = THEME COLORS
  ..................................*/ primary: color-palette__(red-ed1),
  secondary: color-palette__(grey-4d4),
  accent: color-palette__(black)
);

$color-vars-applied-background: (
  /* = BACKGROUND COLORS
  ..................................*/ background-body: color-palette__(white),
  background-screen: #f1f1f1,
  background-hr: color-palette__(black),
  background-button: #c8c8c8,
  background-pre: #eeeeee,
  background-ins: #fff9c0
);

$color-vars-applied-border: (
  /* = BORDER COLORS
  ..................................*/ border-button: #cccccc #cccccc #bbbbbb,
  border-button-hover: #cccccc #bbbbbb #aaaaaa,
  border-button-focus: #aaaaaa #bbbbbb #bbbbbb,
  border-input: #cccccc,
  border-abbr: #666666
);

$color-vars-applied-button: (
  /* = BUTTON COLORS
  ..................................*/ btn: color-palette__(black),
  btn-border: color-palette__(black),
  btn-text: color-palette__(white)
);

$color-vars-applied-text: (
  /* = TEXT COLORS
  ..................................*/ text-main: color-palette__(black),
  text-headings: color-palette__(black),
  text-highlight: color-palette__(red-ed1),
  link: color-palette__(red-ed1),
  link-visited: darken(color-palette__(red-ed1), 25%),
  link-hover: darken(color-palette__(red-ed1), 20%),
  text-input: color-palette__(black),
  text-input-focus: darken(color-palette__(black), 33%),
  text-screen: color-palette__(blue-217)
);

$color-vars-applied: map-collect(
  $color-vars-applied-theme,
  $color-vars-applied-background,
  $color-vars-applied-border,
  $color-vars-applied-button,
  $color-vars-applied-text
);

/* = COMBINE PALETTE and APPLIED COLOR MAPS
----------------------------------------------- */

$color-vars-all: map-collect($color-vars-palette, $color-vars-applied);

/* = ADD COLORS as CSS PROPERTIES (VARIABLES)
Automatic write colors of map to css variables using a SASS loop
----------------------------------------------- */

:root {
  @each $color_name, $color in $color-vars-all {
    --color-#{""+$color-name}: #{$color};
  }
}

/* = CREATE COLOR FUNCTION
-----------------------------------------------

Example Usage
-------------

body {
	background-color: color__(primary-f2f);
}

----------------------------------------------- */

@function color__($color) {
  @return map-get($color-vars-all, $color);
}

/* = DESIGN SYSTEM - OUTPUT
These could be further reduced to a map but I'm out of time ladies and gents!
----------------------------------------------- */

.design-system {
  /* = PALETTE
  ..................................*/

  @each $color_name, $color in $color-vars-common {
    --palette-common--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-brand {
    --palette-brand--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-social {
    --palette-social--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-state {
    --palette-state--color-#{""+$color-name}: #{$color};
  }

  /* = APPLIED
  ..................................*/

  @each $color_name, $color in $color-vars-applied-theme {
    --applied-theme--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-applied-background {
    --applied-background--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-applied-border {
    --applied-border--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-applied-button {
    --applied-button--color-#{""+$color-name}: #{$color};
  }

  @each $color_name, $color in $color-vars-applied-text {
    --applied-text--color-#{""+$color-name}: #{$color};
  }
}
