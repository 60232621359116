/*------------------------------------*\
	# FORMS -- FIELDS
\*------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="checkbox"] {
	width: $check-size;
	height: $check-size;
	border: none;
	outline: none;
}

input[type="radio"] {
	width: $radio-size;
	height: $radio-size;
	border: none;
	border-radius: 100%;
	outline: none;
}

input[type="checkbox"]:hover ~ label,
input[type="checkbox"]:focus ~ label,
input[type="radio"]:hover	~ label,
input[type="radio"]:focus	~ label {
	border: 1px solid $color__form-border;
	background-color: $color__text-input-focus-bg;
}


// Jen Simmons: https://codepen.io/jensimmons/pen/KKPzxJa
@supports (-webkit-appearance: none) {

	/* 	AKA, only do this if `-webkit-appearance: none` is supported. 
	Which means IE11 and Opera Mini will get the old browser-styled checkbox. 
	Which works just fine. Old browsers can get old looking things. */

	input[type="checkbox"],
	input[type="radio"] {
		cursor: pointer; 
	}

	input[type="checkbox"] {
		-webkit-appearance: none;
		width: $check-size;
		height: $check-size;
		border: 1px solid $color__form-border;
		outline: none;
	}

	input[type="radio"] {
		-webkit-appearance: none;
		width: $radio-size;
		height: $radio-size;
		border: 1px solid $color__form-border;
		border-radius: 100%;
		outline: none;
	}

	input[type="checkbox"]:checked,
	input[type="radio"]:checked {
		position: relative;
		background: none;
	}

	input[type="checkbox"]:checked::after {
		/* Place (position) the new checkmark. */
		position: absolute;
		top: 1px; 
		left: 0.08rem;
		/*  Create a checkmark in CSS. 
			Could use an SVG or `content: "✓";` instead. 
			This is a box, turned 45 degs, with a 
			left and bottom border to make the checkmark.
			Created using an empty pseudo-element.*/
		content: "";
		width: 0.9rem;
		height: 0.45rem;
		border: 3px solid $color__checkbox-tick;
		border-right: none;
		border-top: none;
		transform: rotate(-45deg);
	}

	input[type="radio"]:checked::after {
		$dot_size: $radio-inner-size;

		position: absolute;
			top: calc(50% - ( #{$dot_size} / 2 ));
			left: calc(50% - ( #{$dot_size} / 2 ));
		content: "";
		background: $color__radio-dot;
		height: $dot_size;
		width: $dot_size;
		border-radius: 100%;
	}

}


input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
	border: 0;
	padding: 0;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
select,
textarea {
	color: $color__text-input;
	background-color: $color__text-input-background;
	border: 1px solid $color__form-border;
	border-radius: $input-radius;
	letter-spacing: 0.020em;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	max-width: 100%;
	transition: 0.2s ease-in-out all;

	.ie & {
		line-height: 1;
	}

	&::placeholder {
		font-family: $font__serif;
		font-style: italic;
		color: $color__text-input-placeholder;
	}

}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
select:focus,
textarea:focus {
	color: $color__text-input-focus;
	border: 1px solid $color__form-border;
	background-color: $color__text-input-focus-bg;
	outline: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
select {
	padding: $input-padding;
	font-weight: 400;
}

textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 100%;

	padding: $input-padding;
}

.checkboxes {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

select {
	display: block;
	padding: $input-padding;
	padding-right: 40px;
	width: 100%;
	max-width: 100%; 
	box-sizing: border-box;
	margin: 0;

	font-family: $font__sans-serif;
	color: #444;
	letter-spacing: 0.050em;
	
	border: 1px solid $color__form-border;
	box-shadow: none;
	border-radius: $input-radius;
	appearance: none;
	background-color: $color__text-input-background;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.4' height='6.1' viewBox='0 0 9.4 6.1'%3E%3Cpath d='M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z' transform='translate(-2 -2)' fill='%23434343'/%3E%3C/svg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

select::-ms-expand {
	display: none;
}
select:hover {
	cursor: pointer;
}

select:focus {
	border-color: $color__select-focus-border;
	color: $color__select-text;
	outline: none;
}
select option {
	font-weight:normal;
}

