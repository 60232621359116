/*------------------------------------*\
	# LISTS
\*------------------------------------*/

ul,
ol {
	list-style: none;
	li {
		margin-right: 1em;
	}
}

.entry-content ul {
	list-style: disc;
}

.entry-content ol {
	list-style: decimal;
	@include bp($sm) {
		margin: 0 0 0 2.5em;  
	}
}

ul ul, ol ol, ul ol, ol ul {
	margin-top: 0;
	margin-bottom: 0;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0;
}
