/*------------------------------------*\
	# GRAVITY FORMS CSS READY CLASSES 
\*------------------------------------*/

@media only screen and (min-width:801px) {

	.gform_wrapper .top_label li.gfield.gf_inline {
		vertical-align: top;
		width: auto !important;
		margin: 0;
		padding-right: 16px;
		float: none !important;
	}

	.gform_wrapper .top_label li.gfield.gf_inline input[type=email].large,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=email].medium,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=email].small,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=number].large,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=number].medium,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=number].small,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=password].large,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=password].medium,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=password].small,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=tel].large,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=tel].medium,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=tel].small,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=text].large,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=text].medium,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=text].small,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=url].large,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=url].medium,
	.gform_wrapper .top_label li.gfield.gf_inline input[type=url].small {
		width: 100%
	}

	.gform_wrapper .top_label li.gfield.gf_inline input[type=text].datepicker.medium {
		width: 96px !important
	}

	.gform_wrapper .top_label li.gfield.gf_inline select,
	.gform_wrapper .top_label li.gfield.gf_inline textarea {
		width: 100%
	}

	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_hour input[type=text],
	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_minute input[type=text] {
		width: 70% !important
	}

	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_day,
	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_month,
	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_year,
	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_hour,
	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_minute {
		width: 50px
	}

	.gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_ampm {
		width: auto
	}

	.gform_wrapper li.gf_inline div.ginput_container {
		white-space: nowrap !important
	}

	.gform_wrapper li.gf_inline div.ginput_container.ginput_container_date label {
		display: block !important
	}

	.gform_wrapper li.gfield.gfield_error.gf_inline {
		padding-right: 0
	}

	.gform_wrapper .top_label li.gfield.gf_left_half,
	.gform_wrapper .top_label li.gfield.gf_right_half {
		vertical-align: top;
		width: 50%;
		float: none
	}

	.gform_wrapper .top_label li.gfield.gf_left_half + .gf_left_half {
		margin-right: 10px;
	}

	.gform_wrapper .top_label li.gfield.gf_left_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker),
	.gform_wrapper .top_label li.gfield.gf_right_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker) {
		width: 100% !important;
		padding-left: 0;
		padding-right: 0
	}

	.gform_wrapper .top_label li.gfield.gf_left_half:before {
		content: "";
		display: block;
		clear: both
	}

	.gform_wrapper .top_label li.gfield.gf_right_half:after {
		content: "";
		display: table;
		clear: both
	}

	.gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_left_half,
	.gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_right_half {
		max-width: 50%;
	}

	.gform_wrapper .top_label li.gfield.gfield_error.gf_left_half {
	}

	.gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) input.large,
	.gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) input.medium,
	.gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) select.large,
	.gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) select.medium,
	.gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) input.large,
	.gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) input.medium,
	.gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) select.large,
	.gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) select.medium {
		width: 100%
	}

	.gform_wrapper .top_label li.gfield.gf_left_half textarea,
	.gform_wrapper .top_label li.gfield.gf_right_half textarea {
		width: 100%
	}

	.gform_wrapper .top_label li.gfield.gf_left_half input.small,
	.gform_wrapper .top_label li.gfield.gf_right_half input.small {
		width: 35%
	}

	.gform_wrapper .top_label li.gfield.gf_left_half+li.gfield.gsection.gf_left_half,
	.gform_wrapper .top_label li.gfield.gf_left_half+li.gfield.gsection.gf_right_half,
	.gform_wrapper .top_label li.gfield.gf_left_half+li.gsection,
	.gform_wrapper .top_label li.gfield.gf_right_half+li.gfield.gsection.gf_left_half,
	.gform_wrapper .top_label li.gfield.gf_right_half+li.gfield.gsection.gf_right_half,
	.gform_wrapper .top_label li.gfield.gf_right_half+li.gsection,
	.gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_left_half,
	.gform_wrapper .top_label li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_left_half,
	.gform_wrapper .top_label li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_right_half {
	}

	.gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half {
	}

	.gform_wrapper .top_label li.gfield.gf_first_quarter+li.gsection,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter+li.gsection,
	.gform_wrapper .top_label li.gfield.gf_left_third+li.gsection,
	.gform_wrapper .top_label li.gfield.gf_right_third+li.gsection,
	.gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half {
	}

	.gform_wrapper .top_label li.gfield.gf_left_half+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_left_half+li.gfield,
	.gform_wrapper .top_label li.gfield.gf_right_half+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_right_half+li.gfield {
		clear: both
	}

	.gform_wrapper .top_label li.gfield.gf_left_third,
	.gform_wrapper .top_label li.gfield.gf_middle_third,
	.gform_wrapper .top_label li.gfield.gf_right_third {
		margin-bottom: 8px
	}

	.gform_wrapper .top_label li.gfield.gf_left_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
	.gform_wrapper .top_label li.gfield.gf_middle_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
		width: 33.3%
	}

	.gform_wrapper .top_label li.gfield.gf_right_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
		width: 33.4%
	}

	.gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input.large,
	.gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input.medium,
	.gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select.large,
	.gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select.medium,
	.gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input.large,
	.gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input.medium,
	.gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select.large,
	.gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select.medium,
	.gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input.large,
	.gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input.medium,
	.gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select.large,
	.gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select.medium {
		width: 100%
	}

	.gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]):not(.ginput_quantity),
	.gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select,
	.gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]):not(.ginput_quantity),
	.gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select,
	.gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]):not(.ginput_quantity),
	.gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select {
		width: 100% !important
	}

	.gform_wrapper .top_label li.gfield.gfield_error.gf_left_third,
	.gform_wrapper .top_label li.gfield.gfield_error.gf_middle_third {
		width: 33.3%;
	}

	.gform_wrapper .top_label li.gfield.gfield_error.gf_right_third {
		width: 33.4%
	}

	.gform_wrapper .top_label li.gfield.gf_right_third:after {
		content: "";
		display: table;
		clear: both
	}

	.gform_wrapper .top_label li.gfield.gf_left_third+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_left_third+li.gfield,
	.gform_wrapper .top_label li.gfield.gf_middle_third+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_middle_third+li.gfield,
	.gform_wrapper .top_label li.gfield.gf_right_third+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_right_third+li.gfield {
		clear: both
	}

	.gform_wrapper .top_label li.gfield.gf_first_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
	.gform_wrapper .top_label li.gfield.gf_second_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
	.gform_wrapper .top_label li.gfield.gf_third_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
		width: 25%;
		vertical-align: top;
		float: none
	}

	.gform_wrapper .top_label li.gfield.gf_first_quarter,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter,
	.gform_wrapper .top_label li.gfield.gf_second_quarter,
	.gform_wrapper .top_label li.gfield.gf_third_quarter {
		margin-bottom: 8px
	}

	.gform_wrapper .top_label li.gfield.gf_first_quarter div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]),
	.gform_wrapper .top_label li.gfield.gf_first_quarter div:not(.ginput_container_date) select,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]),
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter div:not(.ginput_container_date) select,
	.gform_wrapper .top_label li.gfield.gf_second_quarter div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]),
	.gform_wrapper .top_label li.gfield.gf_second_quarter div:not(.ginput_container_date) select,
	.gform_wrapper .top_label li.gfield.gf_third_quarter div:not(.ginput_container_date) input:not([type=radio]):not([type=checkbox]),
	.gform_wrapper .top_label li.gfield.gf_third_quarter div:not(.ginput_container_date) select {
		width: 100% !important
	}

	.gform_wrapper .top_label li.gfield.gfield_error.gf_first_quarter,
	.gform_wrapper .top_label li.gfield.gfield_error.gf_fourth_quarter,
	.gform_wrapper .top_label li.gfield.gfield_error.gf_second_quarter,
	.gform_wrapper .top_label li.gfield.gfield_error.gf_third_quarter {
		width: 24.5%
	}

	.gform_wrapper .top_label li.gfield.gf_fourth_quarter:after {
		content: "";
		display: table;
		clear: both
	}

	.gform_wrapper .top_label li.gfield.gf_first_quarter input.large,
	.gform_wrapper .top_label li.gfield.gf_first_quarter input.medium,
	.gform_wrapper .top_label li.gfield.gf_first_quarter select.large,
	.gform_wrapper .top_label li.gfield.gf_first_quarter select.medium,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter input.large,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter input.medium,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter select.large,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter select.medium,
	.gform_wrapper .top_label li.gfield.gf_second_quarter input.large,
	.gform_wrapper .top_label li.gfield.gf_second_quarter input.medium,
	.gform_wrapper .top_label li.gfield.gf_second_quarter select.large,
	.gform_wrapper .top_label li.gfield.gf_second_quarter select.medium,
	.gform_wrapper .top_label li.gfield.gf_third_quarter input.large,
	.gform_wrapper .top_label li.gfield.gf_third_quarter input.medium,
	.gform_wrapper .top_label li.gfield.gf_third_quarter select.large,
	.gform_wrapper .top_label li.gfield.gf_third_quarter select.medium {
		width: 97.5%
	}

	.gform_wrapper .top_label li.gfield.gf_first_quarter+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_first_quarter+li.gfield,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_fourth_quarter+li.gfield,
	.gform_wrapper .top_label li.gfield.gf_second_quarter+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_second_quarter+li.gfield,
	.gform_wrapper .top_label li.gfield.gf_third_quarter+.gform_footer,
	.gform_wrapper .top_label li.gfield.gf_third_quarter+li.gfield {
		clear: both
	}

	.gform_wrapper .top_label li ul.gfield_checkbox,
	.gform_wrapper .top_label li ul.gfield_radio {
	}

	.gform_wrapper ul.gform_fields:not(.top_label) li ul.gfield_checkbox,
	.gform_wrapper ul.gform_fields:not(.top_label) li ul.gfield_radio {
		width: 70%
	}

	.gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li {
		width: 50%
	}

	.gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li {
		width: 33.3%;
	}

	.gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li label,
	.gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li label,
	.gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li label,
	.gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li label,
	.gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li label,
	.gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle
	}

	.gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li {
		width: 25%;
	}

	.gform_wrapper li.gfield.gf_list_5col ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_5col ul.gfield_radio li {
		width: 20%;
	}

	.gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
	.gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_2col label.gfield_label,
	.gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_3col label.gfield_label,
	.gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_4col label.gfield_label,
	.gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_5col label.gfield_label,
	.gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_inline label.gfield_label {
		margin: 0 0 10px
	}

	.gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox,
	.gform_wrapper li.gfield.gf_list_2col ul.gfield_radio,
	.gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox,
	.gform_wrapper li.gfield.gf_list_3col ul.gfield_radio,
	.gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox,
	.gform_wrapper li.gfield.gf_list_4col ul.gfield_radio,
	.gform_wrapper li.gfield.gf_list_5col ul.gfield_checkbox,
	.gform_wrapper li.gfield.gf_list_5col ul.gfield_radio {
		overflow: hidden
	}

	.gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox {
		column-gap: 16px
	}

	.gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_2col_vertical .ginput_container_radio ul.gfield_radio {
		width: calc(100% - 16px);
		column-count: 2
	}

	.gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_3col_vertical .ginput_container_radio ul.gfield_radio {
		width: calc(100% - 8px);
		column-count: 3
	}

	.gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_4col_vertical .ginput_container_radio ul.gfield_radio {
		width: calc(100% - 8px);
		column-count: 4
	}

	.gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox,
	.gform_wrapper li.gf_list_5col_vertical .ginput_container_radio ul.gfield_radio {
		width: calc(100% - 8px);
		column-count: 5
	}

	.gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
	.gform_wrapper li.gf_list_2col_vertical .ginput_container_radio ul.gfield_radio li label,
	.gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
	.gform_wrapper li.gf_list_3col_vertical .ginput_container_radio ul.gfield_radio li label,
	.gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
	.gform_wrapper li.gf_list_4col_vertical .ginput_container_radio ul.gfield_radio li label,
	.gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
	.gform_wrapper li.gf_list_5col_vertical .ginput_container_radio ul.gfield_radio li label {
		max-width: 75%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis
	}

	.gform_wrapper li.gfield.gf_list_height_25 ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_height_25 ul.gfield_radio li {
		height: 25px
	}

	.gform_wrapper li.gfield.gf_list_height_50 ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_height_50 ul.gfield_radio li {
		height: 50px
	}

	.gform_wrapper li.gfield.gf_list_height_75 ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_height_75 ul.gfield_radio li {
		height: 75px
	}

	.gform_wrapper li.gfield.gf_list_height_100 ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_height_100 ul.gfield_radio li {
		height: 100px
	}

	.gform_wrapper li.gfield.gf_list_height_125 ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_height_125 ul.gfield_radio li {
		height: 125px
	}

	.gform_wrapper li.gfield.gf_list_height_150 ul.gfield_checkbox li,
	.gform_wrapper li.gfield.gf_list_height_150 ul.gfield_radio li {
		height: 150px
	}

	.gform_wrapper li.gf_list_inline ul.gfield_checkbox li,
	.gform_wrapper li.gf_list_inline ul.gfield_radio li {
		width: auto !important;
		float: none !important;
		margin: 0 8px 10px 0;
	}

	html[dir=rtl] .gform_wrapper li.gf_list_inline ul.gfield_checkbox li,
	html[dir=rtl] .gform_wrapper li.gf_list_inline ul.gfield_radio li {
		padding-left: 16px !important;
		padding-right: 0 !important
	}

	.gform_wrapper li.gf_hide_ampm div.gfield_time_ampm {
		display: none !important
	}

	.gform_wrapper li.gsection.gf_scroll_text {
		height: 240px;
		width: 100%;
		padding: 16px;
		background-color: #fff;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.gform_wrapper .top_label li.gsection.gf_scroll_text h2.gsection_title {
		margin: 10px 10px 0 8px !important
	}

	.gform_wrapper.gf_browser_chrome .top_label li.gsection.gf_scroll_text h2.gsection_title,
	.gform_wrapper.gf_browser_gecko .top_label li.gsection.gf_scroll_text h2.gsection_title {
		margin: 0 10px 0 8px !important
	}

	.gform_wrapper .top_label li.gsection.gf_scroll_text .gsection_description {
		margin: 10px;
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul li,
	.gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul li {
		list-style-type: disc !important;
		margin: 0 0 8px;
		overflow: visible;
		padding-left: 0
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul,
	.gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul {
		list-style-type: disc !important;
		margin: 16px 0 16px 18px;
		padding-left: 0
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li,
	.gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li {
		list-style-type: decimal !important;
		overflow: visible;
		margin: 0 0 8px;
		padding-left: 0
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol,
	.gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol {
		list-style-type: decimal !important;
		margin: 16px 0 18px 32px;
		padding-left: 0
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul,
	.gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul li {
		list-style-type: disc !important;
		padding-left: 0
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul {
		margin: 16px 0 16px 18px
	}

	.gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul li {
		margin: 0 0 8px
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl {
		margin: 0 0 18px;
		padding-left: 0
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl dt {
		font-weight: 700
	}

	.gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl dd {
		margin: 0 0 16px 18px
	}

	.gform_wrapper li.gfield.gfield_html.gf_alert_blue,
	.gform_wrapper li.gfield.gfield_html.gf_alert_gray,
	.gform_wrapper li.gfield.gfield_html.gf_alert_green,
	.gform_wrapper li.gfield.gfield_html.gf_alert_red,
	.gform_wrapper li.gfield.gfield_html.gf_alert_yellow {
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		margin: 20px 0 !important;
		padding: 20px !important
	}

	.gform_wrapper li.gfield.gfield_html.gf_alert_green {
		border: 1px solid #97B48A;
		background-color: #CBECA0;
		text-shadow: #DFB 1px 1px;
		color: #030
	}

	.gform_wrapper li.gfield.gfield_html.gf_alert_red {
		border: 1px solid #CFADB3;
		background-color: #FAF2F5;
		text-shadow: #FFF 1px 1px;
		color: #832525
	}

	.gform_wrapper li.gfield.gfield_html.gf_alert_yellow {
		border: 1px solid #E6DB55;
		background-color: #FFFBCC;
		text-shadow: #FCFAEA 1px 1px;
		color: #222
	}

	.gform_wrapper li.gfield.gfield_html.gf_alert_gray {
		border: 1px solid #CCC;
		background-color: #EEE;
		text-shadow: #FFF 1px 1px;
		color: #424242
	}

	.gform_wrapper li.gfield.gfield_html.gf_alert_blue {
		border: 1px solid #a7c2e7;
		background-color: #D1E4F3;
		text-shadow: #E0F1FF 1px 1px;
		color: #314475
	}

	div.gform_confirmation_wrapper.gf_confirmation_simple_yellow {
		margin: 30px 0;
		max-width: 99%;
		border-top: 1px solid #E6DB55;
		border-bottom: 1px solid #E6DB55;
		padding: 32px;
		background-color: #FFFBCC;
		color: #424242;
	}

	div.gform_confirmation_wrapper.gf_confirmation_simple_gray {
		margin: 30px 0;
		max-width: 99%;
		border-top: 1px solid #CCC;
		border-bottom: 1px solid #CCC;
		padding: 32px;
		background-color: #EAEAEA;
		color: #424242;
	}

	div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient {
		position: relative;
		background-color: #FFFBD2;
		margin: 30px 0;
		border: 1px solid #E6DB55;
		-webkit-box-shadow: 0 0 5px rgba(221, 215, 131, .75);
		-moz-box-shadow: 0 0 5px rgba(221, 215, 131, .75);
		box-shadow: 0 0 5px rgba(221, 215, 131, .75)
	}

	div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient div.gforms_confirmation_message {
		margin: 0;
		padding: 40px;
		max-width: 99%;
		border-top: 2px solid #FFF;
		border-bottom: 1px solid #E6DB55;
		color: #424242;
		background: #fffce5;
		background: -moz-linear-gradient(top, #fffce5 0, #fff9bf 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fffce5), color-stop(100%, #fff9bf));
		background: -webkit-linear-gradient(top, #fffce5 0, #fff9bf 100%);
		background: -o-linear-gradient(top, #fffce5 0, #fff9bf 100%);
		background: -ms-linear-gradient(top, #fffce5 0, #fff9bf 100%);
		background: linear-gradient(to bottom, #fffce5 0, #fff9bf 100%)
	}

	div.gform_confirmation_wrapper.gf_confirmation_green_gradient {
		position: relative;
		background-color: #f1fcdf;
		margin: 30px 0;
		border: 1px solid #a7c37c;
		-webkit-box-shadow: 0 0 5px rgba(86, 122, 86, .4);
		-moz-box-shadow: 0 0 5px rgba(86, 122, 86, .4);
		box-shadow: 0 0 5px rgba(86, 122, 86, .4)
	}

	div.gform_confirmation_wrapper.gf_confirmation_green_gradient div.gforms_confirmation_message {
		margin: 0;
		padding: 40px;
		max-width: 99%;
		border-top: 2px solid #effade;
		border-bottom: 1px solid #a7c37c;
		text-shadow: #DFB 1px 1px;
		color: #030;
		background: #dbf2b7;
		background: -moz-linear-gradient(top, rgba(219, 242, 183, 1) 0, rgba(180, 208, 136, 1) 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(219, 242, 183, 1)), color-stop(100%, rgba(180, 208, 136, 1)));
		background: -webkit-linear-gradient(top, rgba(219, 242, 183, 1) 0, rgba(180, 208, 136, 1) 100%);
		background: -o-linear-gradient(top, rgba(219, 242, 183, 1) 0, rgba(180, 208, 136, 1) 100%);
		background: -ms-linear-gradient(top, rgba(219, 242, 183, 1) 0, rgba(180, 208, 136, 1) 100%);
		background: linear-gradient(to bottom, rgba(219, 242, 183, 1) 0, rgba(180, 208, 136, 1) 100%)
	}

	div.gform_confirmation_wrapper.gf_confirmation_green_gradient:after,
	div.gform_confirmation_wrapper.gf_confirmation_green_gradient:before,
	div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:after,
	div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:before {
		z-index: -1;
		position: absolute;
		content: "";
		bottom: 15px;
		left: 10px;
		width: 50%;
		top: 80%;
		max-width: 40%;
		background: rgba(0, 0, 0, .2);
		-webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, .2);
		-moz-box-shadow: 0 15px 10px rgba(0, 0, 0, .2);
		box-shadow: 0 15px 10px rgba(0, 0, 0, .2);
		-webkit-transform: rotate(-3deg);
		-moz-transform: rotate(-3deg);
		-o-transform: rotate(-3deg);
		-ms-transform: rotate(-3deg);
		transform: rotate(-3deg)
	}

	div.gform_confirmation_wrapper.gf_confirmation_green_gradient:after,
	div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:after {
		-webkit-transform: rotate(3deg);
		-moz-transform: rotate(3deg);
		-o-transform: rotate(3deg);
		-ms-transform: rotate(3deg);
		transform: rotate(3deg);
		right: 10px;
		left: auto
	}

	.gform_wrapper.gf_simple_horizontal_wrapper.centered_wrapper {
		width: auto;
		margin: 0 auto;
		display: table
	}

	.gform_wrapper form.gf_simple_horizontal {
		width: 100%;
		margin: 0 auto
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_body,
	.gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
		display: table-cell;
		vertical-align: middle;
		margin: 0;
		padding: 0;
		position: relative
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_body {
		width: auto;
		max-width: 75%
	}

	.gform_wrapper form.gf_simple_horizontal div.ginput_container,
	.gform_wrapper form.gf_simple_horizontal ul li.gfield {
		margin-top: 0 !important
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
		width: auto;
		max-width: 25%;
		padding: 0;
		margin: 0
	}

	body:not(rtl) .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
		text-align: left
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label {
		display: table;
		width: 100%
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield {
		display: table-cell;
		padding-right: 1em;
		height: auto
	}

	.gform_wrapper form.gf_simple_horizontal .gfield_description:not(.validation_message),
	.gform_wrapper form.gf_simple_horizontal .ginput_complex label,
	.gform_wrapper form.gf_simple_horizontal label.gfield_label {
		display: block;
		height: 1px;
		width: 1px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: -9000px
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_above li.gfield,
	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
		vertical-align: middle
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.large,
	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.medium,
	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.small,
	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.large,
	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.medium,
	.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.small {
		width: 100%
	}

	.gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type=submit],
	.gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type=button],
	.gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type=image] {
		height: auto
	}

	.gform_wrapper form.gf_simple_horizontal .top_label .gfield_error {
		margin-bottom: 0
	}

	.gform_wrapper form.gf_simple_horizontal ul li.gfield .ginput_container_checkbox ul li,
	.gform_wrapper form.gf_simple_horizontal ul li.gfield .ginput_container_radio ul li {
	}

	html[dir=rtl] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_2 span {
		width: 48.5%
	}

	html[dir=rtl] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_3 span {
		width: 33%
	}

	html[dir=rtl] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_4 span {
		width: 24.5%
	}

	html[dir=rtl] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_5 span {
		width: 19.5%
	}
}

body .gform_wrapper.gf_form_center_wrapper {
	padding: 16px 0 16px 16px
}