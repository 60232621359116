/*------------------------------------*\
	# TABLE OF CONTENTS
\*------------------------------------*/

// @since baselines 2.0.0

/* Typography Settings
* the layout settings use some of these variables so they
* need to be called first.
*/
@import "base/typography/typography-settings";

// Settings and variables.
@import "variables-settings/variables-settings-master";

//Custom
@import "variables-settings/custom";

// Reset the stylesheet yo!
@import "base/normalize";

// Grid Settings.
@import "layout/layout-settings";

// Useful mixins like clearfix.
@import "mixins/mixins-master";

// Let's get the basics down for elements and components.
@import "base/elements/elements-master";

// All of the typography is handled here.
@import "base/typography/typography-master";

//Bootstrap
@import "bootstrap/scss/bootstrap";

// Accesibility.
@import "base/accessibility";

// Layout Stack
@import "layout/stack";

// Layout Tools
@import "layout/layout-tools";

// A composition of components from the base.
@import "modules/site/site-master";

// Module for navigation, main-navigation, menus, sidebar menus
@import "modules/navigation/navigation-master";

// Buttons
@import "modules/button";

// Base form management.
@import "base/forms/forms-master";

//Modules
@import "modules/two-col-image-checklist";
@import "modules/three-col-box";
@import "modules/two-col-box";
@import "modules/subscription-form";
@import "modules/heading-list-cards";
@import "modules/full-image";
@import "modules/heading-cards";
@import "modules/single-video";
@import "modules/image-text";
@import "modules/heading-image-cards";
@import "modules/heading-image-prompt-cards";
@import "modules/heading-image-single-card";
@import "modules/teams-cards";
@import "modules/three-col-checklist";
@import "modules/what-we-do";
@import "modules/circle-progress";
@import "modules/circle-progress-image-cards";
@import "modules/two-col-center-image-slider";
@import "modules/products";
@import "modules/enquiry-form";
@import "modules/overlay";

// Icons
@import "modules/icons";

// Alignments for WYSIWYG aligning
@import "layout/alignments";

// Modules for managing collective images. This is also often used in the WYSIWYG
@import "modules/media/media-master";

// CLEARFIX your layouts here.
@import "layout/clearings";

// Layouts, it's final
@import "layout/layout-master";

// Utilites Master
@import "utilities/utilities-master";

// Shame Shame Shame
@import "shame";
