/*------------------------------------*\
	# LINKS
\*------------------------------------*/

a {
	color: color__(link);
	position: relative; 
	// text-decoration: none;
	transition: color 200ms ease;

	&:hover,
	&:focus,
	&:active {
		color: color__(link-hover);
	}
}