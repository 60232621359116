/*------------------------------------*\
	# SITE - MASTER
\*------------------------------------*/

@import "site-header";
@import "site-footer";

.overflow {
	overflow: hidden;
}

.active {
	color: $primary-1-1;
}

:target {
    scroll-margin-top: 75px;
	@include bp($md) {
		scroll-margin-top: 90px;
	}
}

.site {
	overscroll-behavior: contain;
}
