.three-col-box {
    margin: auto;
    position: relative;
    z-index: 2;
    &.orange {
        background-color: $neutral-3-1;
        border: 1px solid $neutral-3-5;
        .heading {
            color: $secondary-2-6;
        }
        .paragraph {
            color: $secondary-2-6;
        }
    }
    &.blue {
        background-color: $tertiary-1-1;
        border: 1px solid $white;
        .heading {
            color: $tertiary-1-2;
        }
        .paragraph {
            color: $white;
        }
    }
    &.pink {
        background-color: $neutral-2-1;
        border: 1px solid $secondary-1-7;
        .heading {
            color: $secondary-1-8;
        }
        .paragraph {
            color: $secondary-1-8;
        }
    }
}
