/*------------------------------------*\
	# FONTS
\*------------------------------------*/

$font__sans-serif: "IBM Plex Sans", sans-serif;
$font__sans-serif-headings: "Plus Jakarta Sans", sans-serif;
$font__sans-serif-condensed-black: "HelveticaNeue-CondensedBlack", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font__sans-serif-condensed-bold: "HelveticaNeue-CondensedBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font__sans-serif-bold-italic: "HelveticaNeue-BoldItalic", "HelveticaNeue-CondensedBold", "Helvetica Neue", Helvetica,
	Arial, sans-serif;

$font__serif: "Crimson Text", Georgia, Cambria, "Times New Roman", Times, serif;
$font__serif-headings: "Libre Baskerville", Georgia, Cambria, "Times New Roman", Times, serif;

$font__main: $font__sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
