/*------------------------------------*\
	# GRAVITY FORMS 

	- https://gist.github.com/forgeandsmith/8048759
	- Turn off gravity forms styles in settings
	- WARNING: This is for general styles
		Make a new module to make specific one off forms.
\*------------------------------------*/



/* = CORE CONTAINERS
----------------------------------------------- */

.gform_heading,
.gform_body,
.gform_footer {
	clear: both;
}

.gform_heading,
.gform_body {
	clear: both;
}

.gform_heading {
	margin-bottom: 16px;
}

/* = FIELDS CONTAINER
----------------------------------------------- */

.gform_fields {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* = FIELD CONTAINERS AND THEIR CONTENTS
----------------------------------------------- */

.gfield {
	clear: both;
}

.gfield_label {
	display: inline-block;
	margin-bottom: ($field_space * 0.5);

	.left_label & {
		float: left;
		width: 25%;
	}
	.right_label & {
		float: right;
		width: 25%;
	}
}

.gfield_label:empty {
	visibility: hidden;
	position: absolute;
}

.gfield_required {
	padding-left: 5px;
}

.gfield_visibility_hidden {
	display: none;
}



/* = CHECKBOXES AND RADIOS
----------------------------------------------- */

/* ul */.gfield_checkbox,
/* ul */.gfield_radio {
	list-style: none;

	label,
	input {
		cursor: pointer;
	}

	label {
		padding: $input-padding;  
		padding-left: (2 * $field_space) + ($radio-size);
		flex: 1 0 auto;
		max-width: 100% !important; // Fighting ready-class styles

		border: 1px solid $color__form-border;
		border-radius: $input-radius;

		transition: 0.1s ease-in-out all;
	}

	label:hover,
	input:hover + label {
		border-color: $color__form-border;
	}

	input {
		background-color: $color__input-bg;
		margin-right: 16px;
		top: -1px;
		flex-shrink: 0;

		position: absolute;
			top: calc(50% - 8px);
			left: $field_space + ($field_space * 0.5);
	}

	input[value="gf_other_choice"] {
		z-index: 99;
	}

	input[value="text"] {
		padding-left: 48px;
		flex: 1 0 auto;
		max-width: 100% !important;
		border: 1px solid #D9DBE0;
		border-radius: 4px;
		transition: 0.1s ease-in-out all;
		position: relative;
		left: 0px;
		top: 0;
		margin-right: 0;
	}

	input:checked {
		position: absolute;
		background-color: $color__input-checked-bg;
	}

	input:checked ~ label {
		border-color: $color__form-border;
	}

	li {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		position: relative;
	}


	li:hover label {
		color: $color__li-hover-label;
	}

}



/* ul */.gfield_radio input {
	margin-left: 2px;
	margin-right: 10px;
}


/* = TITLES and DESCRIPTIONS
----------------------------------------------- */

.gform_title {
	font-weight: 500;
	margin: 10px 0 6px;
	font-size: 1.25em;
	letter-spacing: normal;
}

.gsection_title {
	margin: 0;
	padding: 0;
}

.gfield_description {
	@include font-size(14);
	color: $color__gf-description;
}

.gfield_time_ampm_shim {
	height: 18px;
}


/* = SECTION BREAK
----------------------------------------------- */

.gsection {
	border-bottom: 1px solid $color__gf-section-border;
}


/* = TIME FIELD 
----------------------------------------------- */

.gfield_time_hour, 
.gfield_time_minute, 
.gfield_time_ampm {
	display: inline;
	display: inline-block;
}

.gfield_time_hour, 
.gfield_time_minute {

	label {
		display: block;
	}
}

.gfield_time_ampm {
	vertical-align: top;
}





/* = DATE PICKER
----------------------------------------------- */

.ui-datepicker {
	display: none; // Initial load

	background: $color__date-picker-bg;
	border: 1px solid $color__form-border;
	padding: 5px;
	max-width: 100%;
	width: 260px;

	box-shadow: 5px 5px 10px transparentize(color__(black), 0.8);

	a {
		cursor: pointer;
	}

	select {
		margin-bottom: 5px;
	}
}

.ui-datepicker-prev,
.ui-datepicker-next {
	display: inline-block;
	padding: 5px;
}

.ui-datepicker-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.ui-datepicker-title {
	width: 100%;

	display: flex;
	justify-content: space-between;

	> select + select {
		margin-left: ($field_space);
	}

}

.ui-datepicker [data-handler="selectDay"] a {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	padding: 4px;
	border-radius: $input-radius;
	background-color: transparent;

	transition: 0.2s ease-in-out all;

	&:hover {
		background-color: lighten(color__(primary), 30%);
		color: color__(white);
	}

	&.ui-state-active {
		background-color: color__(primary);
		color: color__(white);
	}

}






/* = DATEPICKER FIELD WITH CALENDAR ICON
----------------------------------------------- */

input[type="text"].datepicker_with_icon {
	padding-left: 50px;
}

.ginput_container_date {
	position: relative;
}

img.ui-datepicker-trigger {
	position: absolute;
		top: calc(50% - 8px);
		left: 20px;
}




/* =  DRAG AND DROP AREA
----------------------------------------------- */

.gform_fileupload_multifile .gform_drop_area {
	padding: 40px;
	border: 2px dashed $color__form-border;
	border-radius: $input-radius;
	text-align: center;
	color: #AAA;
	margin-bottom: 16px;
	background: transparent;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> * + * {
		margin-top: 20px;
	}

}

.gform_drop_instructions {
	@include font-size(16);
	letter-spacing: 0.030em;
	color: color__(black);

	&::before {
		display: block;
		content: "";
		background-position: center center;
		background-size: 100%;
		width: 20px;
		height: 22px;

		margin: 0 auto 20px;
	}

}

[id*="gform_preview_"] + * {
	margin-top: 10px;
}


/* = AJAX LOADER SPINNER
----------------------------------------------- */

.gform_ajax_spinner {
	margin-right: 0;
	width: 40px;
	height: 40px;
}




/* = VALIDATION AND ERRORS
----------------------------------------------- */

.validation_list {
	padding: ($field_space * 0.5);
}

.validation_error {
	margin-bottom: 10px;
	padding: 10px 20px;
	margin: 0;
	margin-bottom: 20px;

	color: color__(negative);

	border: 1px solid color__(negative);
	background: lighten(color__(negative), 55%);
}

li.gfield {

	&.gfield_error {
		background: lighten(color__(negative), 50%);
		margin-bottom: $field_space;
		padding: $field_space;
	}

	.validation_message {
		color: color__(negative);
		margin-top: 10px;
	}

}

.gform_validation_error .gfield_error {

	input,
	select,
	textarea {
		border: 1px solid #fa6a6a !important;
	}

	
}

.gform_validation_error .gform_fields {
	padding: ($field_space * 0.5);
}

.gform_validation_container {
	display: none !important;
}






/* = LAYOUT
----------------------------------------------- */

.gform_body {
	margin-bottom: $field_space;
}

.gform_footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	margin: -($field_space * 0.5);
}

.ginput_container {
	margin-top: 0;

	input,
	select,
	textarea {

		&.large,
		&.medium,
		&.small {
			width: 100%;
		}

		@media only screen and (min-width:801px) {

			&.large {
				width: 100%;
			}
			&.medium {
				width: 66%;
			}
			&.small {
				width: 33%;
			}

		}

	}

	input[type="search"] {
		-webkit-appearance: textfield;
		box-sizing: border-box;
	}

}


/* ul */.gform_fields,
/* div */.ginput_complex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: -($field_space * 0.5);
}

.ginput_complex label + input,
.ginput_complex label + select {
	margin-top: 6px;
}

.gfield,
.ginput_full,
.ginput_left,
.ginput_right,
.name_first,
.name_last,
.name_prefix {
	width: 100%;
	padding: ($field_space * 0.5);
}

.gf_invisible {
	visibility: hidden;
	position: absolute;
	left: -9999px;
}

.gform_fields > li + li,
.gfmc-column > ul > li + li {
	margin-top: 8px;
}

// No margin for ready classes on the first row
.gfmc-column > ul > li:first-of-type,
.gform_fields > li:first-of-type {

	+ .gf_right_half,
	+ .gf_middle_third,
	+ .gf_middle_third + .gf_right_third {
		margin-top: 0;
	}

}

.gform_button {
	margin: ($field_space * 0.5);
}

.gsection {
	width: 100%;
	padding: (40px - $field_space) $field_space 20px 0;
	margin: ($field_space * 0.5);
	margin-bottom: (30px - $field_space);

	@include bp($tablet) {
		padding: (85px - $field_space) $field_space 20px 0;
	}

}

.gfield_description {
	margin-bottom: ($field_space * 0.5);
}

.ginput_container + .gfield_description {
	margin: 0;
	margin-top: ($field_space * 0.5);
}

.gform_drop_instructions {
	margin-right: 10px;
}

* + [id*="gform_preview"] {
	margin-top: 10px;
}

.gfield_html {
	width: 100%;
}

.ginput_container .clear-multi {
	display: flex;
	flex-wrap: wrap;
	margin: -($field_space * 0.5);
}

.ginput_container .clear-multi > * {
	margin: ($field_space * 0.5);
	flex: 1 0 auto;
}

// Fields in a columns stack differently
[class*="col"] {

	@media only screen and (min-width: 641px) {

		/* ul */.gfield_checkbox,
		/* ul */.gfield_radio {
			flex-direction: row;
		}

	}

}

/* ul */.gfield_checkbox,
/* ul */.gfield_radio,
li.gfmc-column .gfield_checkbox,
li.gfmc-column .gfield_radio {
	padding: 0;
	margin: -($field_space * 0.5);
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;

	li {
		padding: ($field_space * 0.5);
		width: 100%;
	}

	li {

		@media only screen and (min-width: 801px) {
			width: 100%;
		}

	}

}



/* = COMPLEX FIELD */
.ginput_complex {

	.ginput_left,
	.ginput_right {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

}



/* = ADDRESS FIELD */
.ginput_container_address {

	.ginput_full,
	.ginput_left,
	.ginput_right {
		display: flex;
		flex-direction: column;
	}

	.ginput_left,
	.ginput_right {
		width: 50%;
	}

	label {
		font-size: 80%;
		margin-top: 2px;
		text-transform: uppercase;
	}

}


/* = NAME FIELD */

.name_first,
.name_last {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.name_prefix {
	display: flex;
	flex-direction: column;
	width: $fixed-prefix-size;
}
.ginput_complex.has_prefix {
	.name_first {
		width: calc(100% - 110px);
	}

	.name_last {
		width: 100%;
	}
}



/* = DATE FIELD */

.ginput_container_date {

	.clear-multi {
		flex-wrap: nowrap;
	}

	.clear-multi > * {
		flex: 1 1 auto;
		max-width: calc(33.3333% - #{$field_space});
	}

	.ginput_container {
		display: flex;
		flex-direction: column;
	}

}



/* = LIST FIELD */

.gfield_list {
	margin-bottom: 0;
}

.gfield_list_icons {
	width: 30px;
	min-width: 30px;
	max-width: 30px;
}

.gfield_list_group + .gfield_list_group {
	margin-top: $field_space;
}

.gfield_list_cell {
	width: auto;
}

.gfield_list_cell input {
	width: 100%;
}

.gfield_list_cell + .gfield_list_cell,
.ginput_list th + th {
	padding-left: $field_space;
}

.gfield_list_group + .gfield_list_group > td {
	padding-top: $field_space;
}

.add_list_item,
.delete_list_item {
	padding: 0 5px;
}



/* = CONSENT FIELD */

.ginput_container_consent {
	display: flex;
	align-items: center;

	label {
		cursor: pointer;
		margin-left: 16px;
	}

	// Stop the box getting thin on small screens
	input[type="checkbox"] {
		flex: 0 0 auto;
	}

	input[type="checkbox"]:hover ~ label,
	input[type="checkbox"]:focus ~ label {
		background: transparent;
		border: none;
		color: currentColor !important;
	}

	input[type="checkbox"]:focus {
		border-color: color__(positive);
	}

}



/* = TIME AND DATE */

.ginput_container_time label {
	font-size: 80%;
}

.ginput_container_time i {
	font-weight: bold;
	font-size: 140%;
	font-style: normal;

	padding: 0 4px 0 5px;
}

.ginput_container_time input {
	width: 110px;
}

.gfield_time_hour, .gfield_time_minute, .gfield_time_ampm {
	display: inline;
	display: inline-block;
}
.gfield_time_hour, .gfield_time_minute {
	label {
		display: block;
	}
}
.gfield_time_ampm {
	vertical-align: top;
}

div + .gfield_time_ampm {
	margin-left: ($field_space*0.5);
}



/* = COLUMN PLUGIN LAYOUTS */

.gfmc-column {
	display: block;

	border: 1px solid $color__form-border;
	margin: 8px;
	padding: 20px;

	@media only screen and (min-width: 801px) {
		padding: 40px;
	}

}

.gfmc-column > ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: -($field_space * 0.5);
}





/* = CONFIRMATION
----------------------------------------------- */

.gform_confirmation_wrapper {
	background-color: $color__confirmation-bg;
	padding: $field_space + 6px;
}



/* = LISTS
----------------------------------------------- */

// Add list item icon
// Don't flex me!
.add_list_item,
.delete_list_item {
	flex: 0 0 auto;
}



/* = UPLOAD FILE PREVIEW
----------------------------------------------- */

.ginput_preview {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	padding: $field_space;
	background-color: $color__text-input-focus-bg;
	border-radius: $input-radius;
}

.ginput_preview + .ginput_preview {
	margin-top: 5px;
}

.ginput_preview::before {
	display: block;
	content: "";
	margin-right: 8px;
	width: 15px;
	height: 15px;
	position: relative;
		top: 0px;
	overflow: hidden;
	border-radius: $input-radius;

	background-size: cover;
}

.gform_delete {
	display: block;
	width: 15px;
	height: 15px;
	padding: 23px;
	margin: -16px;
	margin-left: auto;
	border-left: 1px solid transparentize($color__form-border, 0.3);
	order: 99;
	overflow: hidden;

	border-radius: $input-radius;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	background-color: transparent;
	transition: 0.2s ease-in-out all;

	cursor: pointer;

	position: relative;
		top: -1px;

	background-size: 14px;
}

.gform_delete:hover {
	background-color: lighten(color__(negative), 30%);
}

.gform_delete + strong {
	margin-right: 10px;
}

.validation_message li + li {
	margin-top: 4px;
}
