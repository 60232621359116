/*------------------------------------*\
	# CLEARFIXES
\*------------------------------------*/

.clear:before,
.clear:after {
	@include clearfix;
}

.clear:after {
	@include clearfix-after;
}