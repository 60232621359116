/*------------------------------------*\
	# STACK

	- Previously used as .l-rhythm

	@source https://every-layout.dev/layouts/stack/
	@since baselines 2.3.0
\*------------------------------------*/


/* = GAPS
Used for single direction dynamic spacing instead of fixed.
@see _stack.scss
----------------------------------------------- */

$gaps: (
	gap-xxs:    0.5em,
	gap-xs:     0.75em,
	gap-sm:     1.0em,
	gap:        1.5em,
	gap-md:     1.75em,
	gap-lg:     2.0em,
	gap-xl:     2.5em,
	gap-xxl:    3.0em,
);

:root {
	@each $gap_name, $gap in $gaps {
		--#{""+$gap_name}: #{$gap};
	}
}

/*----------------------------------------------- */


.l-stack-reset > *,
.l-margin-reset > * {
	margin: 0;
	margin-block: 0;
}

[class*="l-stack"] > * {
	margin-block: 0;
}

[class*="l-stack"] > * + * {
	--stack-space: var(--gap); // See _layout-settings.scss for --gap sizes
	margin-block-start: var(--stack-space, 1.5rem);
}

/* -----------------------------------------------

EXAMPLE
Add a smaller variation for entire stack.
These just adjust the CSS variable not the architecture.

.l-stack-xs > * + * {
	--stack-space: var(--gap-xs);
}

Add a smaller variation for a single item in the stack.

.stack-space-xs {
	--stack-space: var(--gap-xs);
}

OR for a single once off space change inline.

<div style="--stack-space: 10px"></div>

----------------------------------------------- */


// See $gap to generate more.

@each $gap_name, $gap in $gaps {

	$gap_size: str-replace($gap_name, 'gap-', '');

	// Print all but the default gap.
	// The default gap is not caught by the str-replace 
	// above and prints out .l-stack-gap
	@if $gap_size != "gap" {
		.l-stack-#{$gap_size} > * + *,
		.stack-space-#{$gap_size} {
			--stack-space: var(--#{$gap_name}, #{$gap});
		}
	}

}



/* = TOWER (of terror)
A mutant of the stack layout that has a top and bottom margin.
----------------------------------------------- */

.l-tower > [class*="container-fluid"] {
	margin: 0;
}

.l-tower > * {
	margin-top: 3.75rem;
	margin-bottom: 3.75rem;
	@include bp($lg) {
		margin-top: 6.5rem;
		margin-bottom: 6.5rem;
	}
}

.l-tower-top > :nth-child(1) {
	margin-top: 0 !important;
}