.enquiry-form {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin: 0;
            padding: 0;
            a {
                text-decoration: none;
                &:hover,
                &:focus {
                    color: $primary-1-1;
                }
                svg path {
                    fill: $primary-1-5;
                    transition: 0.2s ease-in-out fill;
                }
                svg:hover path,
                svg:focus path {
                    fill: $primary-1-4;
                }
            }
        }
    }
    .gform_validation_errors h2 {
        font-size: inherit;
    }
    .gfield_label {
        display: none;
    }
    .gfield_validation_message,
    input[type="text"]::placeholder,
    input[type="email"]::placeholder,
    input[type="tel"]::placeholder,
    textarea::placeholder,
    label {
        color: $primary-2-5;
        font-style: inherit;
        font-family: inherit;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea,
    select {
        background-color: transparent;
        color: $primary-2-5;
        border: 1px solid $primary-1-5;
        border-radius: 0;
    }
    input[type="text"]:hover,
    input[type="email"]:hover,
    input[type="tel"]:hover,
    textarea:hover,
    select:hover,
    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="tel"]:focus,
    textarea:focus,
    select:focus {
        background-color: transparent;
        color: $primary-2-5;
        border: 1px solid $primary-1-5;
    }
    select {
        background-image: url(../assets/img/icons/chevron-two.svg);
    }
    textarea {
        resize: none;
    }
    .icons {
        right: 0.5rem;
    }
}
