/*------------------------------------*\
	# SITE FOOTER
\*------------------------------------*/

.site-footer {
	ul {
		margin: 0;
		padding: 0;
		li {
			margin: 0;
			a {
				word-break: break-word;
				text-decoration: none;
				color: inherit;
				&:hover,
				&:focus {
					color: $primary-1-1;
				}
				svg path {
					fill: $primary-1-5;
					transition: 0.2s ease-in-out fill;
				}
				svg:hover path,
				svg:focus path {
					fill: $primary-1-4;
				}
			}
		}
	}
	.contact {
		li {
			border-top: 1px solid $primary-1-5;
			border-right: 1px solid $primary-1-5;
			border-left: 1px solid $primary-1-5;
			@include bp($md) {
				border-right: initial;
				border-bottom: 1px solid $primary-1-5;
			}
			&:last-child {
				border-bottom: 1px solid $primary-1-5;
				@include bp($md) {
					border-right: 1px solid $primary-1-5;
				}
			}
		}
	}
	.footer-main-menu {
		li {
			border-bottom: 1px solid $primary-2-6;
			padding-bottom: 1rem;
			margin-top: 0.5rem;
			&:first-child {
				margin-top: 0;
			}
			a {
				&:hover,
				&:focus {
					color: $primary-1-1;
				}
			}
		}
	}
	.footer-sub-menu {
		ul {
			@include bp($md) {
				border-top: 1px solid $primary-1-5;
				padding-top: 1rem;
			}
			li {
				padding-top: 1rem;
				&:first-child {
					padding-top: 0;
				}
				&:nth-child(2) {
					margin-top: 1rem;
					border-top: 1px solid $primary-1-5;
					@include bp($md) {
						border-top: initial;
						margin-top: initial;
					}
				}
				a {
					&:hover,
					&:focus {
						color: $primary-1-1;
					}
				}
			}
		}
	}
	img {
		max-width: 33%;
		height: 100%;
		max-height: 66px;
	}
	p {
		word-break: break-word;
	}
	.lateral {
		text-decoration: none;
		transition: 0.2s ease-in-out color;
		&:hover,
		&:focus {
			color: $primary-1-5;
		}
	}
}
