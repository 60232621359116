.two-col-box {
    position: relative;
    z-index: 1;
    svg path {
        opacity: 0;
        transition: ease-in-out opacity 0.4s;
    }
    .theme {
       transition: 0.4s ease-in-out background-color;
       position: relative;
    }
    .button {
        opacity: 1;
        transition: ease-in-out opacity 0.4s, ease-in-out transform 0.3s, ease-in-out color 0.3s, ease-in-out border-bottom 0.3s;
        transform: translateX(0);
    }
    .heading,
    .paragraph {
        transition: ease-in-out color 0.3s;
    }
    a {
        text-decoration: none;
    }
    a:hover,
    a:focus {
        .button {
            opacity: 0;
            transform: translateX(100%);
        }
        svg path {
            opacity: 1;
        }
    }
    .maroon {
        background-color: $secondary-1-5;
        border: 1px solid $secondary-1-3;
        .heading {
            color: $neutral-3-2;
        }
        .paragraph {
            color: $neutral-3-2;
        }
        svg path {
            fill: $secondary-1-1;
        }
        &:hover,
        &:focus {
            background-color: $secondary-1-4;
        }
    }
    .green-outline {
        border: 1px solid $primary-1-5;
        .heading {
            color: $primary-1-5;
        }
        .paragraph {
            color: $primary-1-5;
        }
        svg path {
            fill: $primary-1-5;
        }
    }
    .green-dark {
        background-color: $primary-1-5;
        border: 1px solid $neutral-2-4;
        .heading {
            color: $neutral-2-5;
        }
        .paragraph {
            color: $neutral-2-5;
        }
        svg path {
            fill: $neutral-2-5;
        }
        &:hover,
        &:focus {
            background-color: $primary-1-4;
        }
    }
    .green-light {
        background-color: $neutral-2-5;
        border: 1px solid $primary-1-2;
        .heading {
            color: $primary-1-4;
        }
        .paragraph {
            color: $primary-1-4;
        }
        svg path {
            fill: $neutral-2-5;
        }
        &:hover,
        &:focus {
            background-color: $primary-1-4;
            border: 1px solid $primary-1-2;
            .heading {
                color: $neutral-2-5;
            }
            .paragraph {
                color: $neutral-2-5;
            }
            .button {
                color: $neutral-2-5;
                border-bottom: 2px solid $neutral-2-5;
            }
        }
    }
}
