.products {
    img {
        transition: 0.2s linear transform;
        transform: scale(1);
    }
    &.productsSmall {
        img {
            object-fit: cover;
            max-height: 220px;
            @include bp($lg) {
                max-height: 350px;
            }
        }
        .item  {
            &:hover,
            &:focus {
                img {
                    transform: scale(1.05);
                }
                cursor: pointer;
                ~ .button-arrow {
                    color: $primary-1-5;
                    svg path {
                        fill: $primary-1-5;
                    }
                }
            }
        }
    }
    &.productsLarge {
        &.extra {
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                mix-blend-mode: multiply;
                position: absolute;
                background-image: url(../assets/img/icons/shape-five.svg);
                background-size: cover;
                background-position: right;
                top: -212px;
                left: 0;
                width: calc(341px / 2);
                height: 254px;
                z-index: -1;
                @include bp($sm) {
                    top: -425px;
                    width: calc(682px / 2);
                    height: 508px;
                }
            }
        }
        svg {
            width: 42px;
            height: 42px;
            g {
                fill: $primary-1-1;
            }
        }
        img {
            object-fit: cover;
            max-height: 220px;
            @include bp($lg) {
                max-height: 350px;
            }
        }
        .item {
            &:hover,
            &:focus {
                img {
                    transform: scale(1.05);
                }
                cursor: pointer;
            }
        }
    }
}
