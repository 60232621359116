/*------------------------------------*\
	# COPY
\*------------------------------------*/

p,
ul,
ol,
pre,
table,
blockquote {
	margin: 0;
	line-height: 1.5;
}

hr {
	background: color__(background-hr);
	border: none;
	margin: -1px 0;
	height: 1px;
}

ul ul,
ol ol,
ul ol,
ol ul {
	margin-top: 0em;
	margin-bottom: 0em;
}

a,
b,
i,
strong,
em,
small,
code {
	line-height: 1.5;
}

b,
strong {
	font-weight: bold;
}

b,
strong,
em,
small,
code {
	line-height: 1;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

pre {
	background: color__(background-pre);
	font-family: $font__pre;
	line-height: $font__line-height-pre;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: $font__code;
}

abbr,
acronym {
	border-bottom: 1px dotted color__(border-abbr);
	cursor: help;
}

mark,
ins {
	background: color__(background-ins);
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	top: -0.4em;
	vertical-align: baseline;
}

sub {
	top: 0.4em;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

blockquote {
	@if $font__main == $font__sans-serif {
		font-family: $font__sans-serif-headings;
	} @else {
		font-family: $font__serif-headings;
	}
}

cite {
	font-style: normal;
}

/* = BODY
----------------------------------------------- */

[class*="f-body-"] {
	font-family: $font__sans-serif;
	letter-spacing: 0.02em;
	font-weight: 400;
	@include font-size(16);

	@include bp($md) {
		@include font-size(18);
	}

	@include bp($xl) {
		@include font-size(20);
	}
}

.f-body-2 {
	letter-spacing: 0.02em;
	@include font-size(14);

	@include bp($md) {
		@include font-size(15);
	}

	@include bp($xl) {
		@include font-size(16);
	}
}

.f-body-3 {
	letter-spacing: 0.02em;
	@include font-size(14);

	@include bp($md) {
		@include font-size(15);
	}
}

.f-body-4 {
	letter-spacing: 0.02em;
	font-weight: 300;
	@include font-size(17);

	@include bp($md) {
		@include font-size(20);
	}

	@include bp($xl) {
		@include font-size(23);
	}
}

.f-body-5 {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(14);

	@include bp($md) {
		@include font-size(16);
	}
}

.f-body-6 {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.03em;
	font-weight: 700;
	@include font-size(18);

	@include bp($md) {
		@include font-size(20);
	}

	@include bp($xl) {
		@include font-size(22);
	}
}

.f-body-7 {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(16);

	@include bp($md) {
		@include font-size(18);
	}

	@include bp($xl) {
		@include font-size(20);
	}
}

.f-body-8,
.f-body-8 * {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(16);

	@include bp($md) {
		@include font-size(22);
	}
}

.f-body-9,
.f-body-9 * {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(22);

	@include bp($md) {
		@include font-size(27);
	}

	@include bp($xl) {
		@include font-size(33);
	}
}

.f-body-10 {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.05em;
	font-weight: 700;
	@include font-size(14);

	@include bp($md) {
		@include font-size(15);
	}

	@include bp($xl) {
		@include font-size(16);
	}
}

.f-body-11 {
	font-family: $font__sans-serif-headings;
	letter-spacing: 0.02em;
	font-weight: 400;
	@include font-size(12);
}

.f-body-12 {
	letter-spacing: 0.02em;
	font-weight: 300;
	@include font-size(22);

	@include bp($md) {
		@include font-size(27);
	}

	@include bp($xl) {
		@include font-size(33);
	}
}
